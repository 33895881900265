.lender-gap .form-control-gap {
  font-size: 0.8rem;
  border-radius: 0rem;
  background-color: #fff !important;
  border: none;
  border-bottom: 1px solid #999999;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  font-weight: 400;
  line-height: 1.5;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.8);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.lender-gap .disabledInputTextBox {
  font-family: HelveticaNeue;
  font-size: 0.8rem;
  border-radius: 0rem;
  background: #fff;
  padding: 0.5rem 1rem;
  border: none;
  border-bottom: 1px solid #999999;
  color: #000;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  font-weight: 400;
  line-height: 1.5;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.8);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  background: #fff;
  border: 1px solid #9F9F9F;
  border-radius: 5px;
  color: #000;
  height: 100px !important;


}
