/* width */

@import url("../vendor/font-awesome/css/font-awesome.min.css");

::-webkit-scrollbar {
  width: 8px;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;700;900&display=swap");

@font-face {
  font-family: "Helvetica Neue Bold";
  src: url("../font/HelveticaNeueBd.ttf");
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("../font/HelveticaNeueLt.ttf");
}

@font-face {
  font-family: "MuseoSans";
  src: url("../font/MuseoSans_100.ttf");
}

@font-face {
  font-family: "MuseoSans Bold";
  src: url("../font/MuseoSans_500.ttf");
}

/* Track */

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(190, 163, 163);
  border-radius: 10px;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 10px;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #c4c4c4;
}

.graycolor {
  color: gray !important;
}

.font-15 {
  font-size: 15px;
}

.font-22 {
  font-size: 22px;
  color: #cdcdcd;
  font-weight: 600;
}

body {
  background: #fff !important;
}

header {
  padding: 7px 0px;
  z-index: 99;
}

header .container {
  max-width: 98%;
}

@media (min-width: 1200px) {
  .pad-70 {
    padding: 70px 0;
  }

  .pad-50 {
    padding: 50px 0;
  }

  .blueheader .container {
    padding: 0px;
  }
}

.blueheader .logo img {
  padding: 6px 0 4px 8px;
}

.blueheader .loginbtn {
  padding-left: 0px !important;
}

.blueheader .loginbtn a {
  border: none;
  width: auto;
}

.blueheader .nav-link {
  padding: 0.5rem 0.8rem !important;
}

.nav-tabs.opportunity-mang {
  border-bottom: 0px;
}

.tabs-bg .nav-tabs.opportunity-mang .nav-item {
  min-width: 16%;
}

/* .opportunity-mang .nav-link{
    padding: 1rem 1rem !important;
} */

.breadcrumbsec {
  /* background: url(../img/breadcrumb-bg.png); */
  padding: 60px 30px;
  background-size: 100%;
}

.breadcrumbsec h1 {
  font-size: 42px;
  line-height: 38px;
  text-align: center;
  letter-spacing: -0.4px;
  color: #ffffff;
  font-family: HelveticaNeue bold;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.breadcrumbsec p {
  font-size: 21px;
  line-height: 38px;
  font-family: HelveticaNeue medium;
  text-align: center;
  letter-spacing: -0.4px;
  color: #ffffff;
}

.blueheader {
  background: #0f669e;
  z-index: 9999;
}

/*navigation*/

.start-header {
  opacity: 1;
  transform: translateY(0);
  padding: 0px 0;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.start-header.scroll-on {
  box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
  padding: 5px 0;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  background: #fff;
}

.start-header.scroll-on .navbar-brand img {
  height: 28px;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.navigation-wrap {
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.navbar {
  padding: 0;
}

.navbar-toggler {
  float: right;
  border: none;
  padding-right: 10px;
}

.navbar-toggler:active,
.navbar-toggler:focus {
  outline: none;
}

.navbar-light .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 1px solid #000;
  transition: all 300ms linear;
}

.navbar-light .navbar-toggler-icon:after,
.navbar-light .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #0f669e;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}

.navbar-light .navbar-toggler-icon:after {
  top: 8px;
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}


/* .generalBackGroundImage{
  background-image: url(../img/banner-img-general.png);
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: auto;
  width:100%;
  height: auto;
} */

/*end navigation*/

@media (min-width: 1200px) {
  /* .container {
    max-width: 90%;
} */
}

.banner__div {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.top-right {
  position: absolute;
  right: 11.46%;
  top: 16.49%;
  bottom: 28.38%;
  width: 370px;
  height: 645px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  background: #ffffff;
  border-radius: 8px;
}

.top-right span {
  position: absolute;
  height: 24px;
  left: 5.95%;

  top: calc(47% - 24px / 2 - 264px);
  font-family: Helvetica Neue Bold;

  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  letter-spacing: 0.2px;

  color: #183b56;
}

.top-right h1 {
  position: absolute;
  height: 72px;
  left: 5.95%;
  right: 5.95%;
  top: calc(47% - 72px / 2 - 206px);

  /* font-family: Open Sans; */
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #5a7184;
}

.top-right form {
  position: relative;
  left: 7%;
  right: 0px;
  top: 23%;
  bottom: 0%;
}

.top-right label {
  /* font-family: Open Sans; */
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */

  color: #5a7184;
}

/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

.top-right input::-webkit-input-placeholder {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #959ead;
}

.top-right input::-moz-placeholder {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #959ead;
}

.top-right input:-ms-input-placeholder {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #959ead;
}

.top-right input::-ms-input-placeholder {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #959ead;
}

.top-right input::placeholder {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #959ead;
}

.top-right input {
  padding-top: 5px;
  width: 85%;
  background: #f0f0f1;
  border: 1px solid #c3cad9;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 8px;
  height: 48px;
}

.top-right button {
  background: #1565d8;
  border-radius: 8px !important;
  /* font-family: Open Sans!important; */
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  width: 85%;
  /* identical to box height */

  text-align: center;

  color: #ffffff;
  letter-spacing: 1px;
}

.containerPortal {
  position: absolute;
  right: 0;
  margin: 20px;
  max-width: 300px;
  padding: 16px;
  background-color: white;
}

.banner__caption {
  width: 70%;
  position: absolute;
  bottom: 40%;
  left: 10%;
}

.banner__caption_general {
  width: 70%;
  position: absolute;
  top: 28%;
  left: 11%;
}

.banner__caption_lender {
  width: 50%;
  position: absolute;
  bottom: 24%;
  left: 10%;
}

.banner__caption h1 {
  font-style: normal;
  font-family: MuseoSans bold;
  font-size: 57px;
  line-height: 74px;
  color: #fff;
}

.banner__caption_general h1 {
  font-style: normal;
  font-family: MuseoSans bold;
  font-size: 57px;
  line-height: 74px;
  color: #fff;
}

.banner__caption_lender h1 {
  font-style: normal;
  font-family: MuseoSans bold;
  font-size: 57px;
  line-height: 78px;
  color: #fff;
}

.banner__description {
  padding-top: 30px;
  font-family: MuseoSans;
}

.banner__description_general {
  padding-top: 30px;
  font-family: MuseoSans;
}

.banner__description p {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: #fff;
  margin-bottom: 9px;
}

.banner__description_general p {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: #fff;
  margin-bottom: 9px;
}

.banner__description ul {
  font-size: 24px;
  line-height: 32px;
  padding-left: 0px;
  list-style-type: none;
  font-family: MuseoSans;
  display: -webkit-inline-box;
}

.banner__description_general ul {
  font-size: 24px;
  line-height: 32px;
  padding-left: 0px;
  list-style-type: none;
  font-family: MuseoSans 100;
  display: -webkit-inline-box;
}

.banner__description ul li {
  color: #fff;
  padding-right: 20px;
}

.banner__description_general ul li {
  color: #fff;
  padding-right: 20px;
}

.width-100 {
  width: 100% !important;
}

.width-50 {
  width: 50%;
  border-radius: 0;
}

.width-40 {
  width: 40%;
  border-radius: 4px;
}

.card-img-upload .dragimgicon {
  font-size: 17px;
  margin-bottom: 5px;
  margin-right: 7px;
  margin-top: 53px;
}

.card-img-upload .dragimgicon.create {
  margin-top: 30px;
}

.identificationText {
  background: rgba(9, 19, 33, 0.0638304);
  width: 100%;
  padding: 13px;
  color: black;
  height: 47px;
  border: none;
  font-size: 13px;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 20px;
}

.borrowerDocumentNotes {
  background: rgba(255, 255, 255, 0.064);
  width: 80%;
  padding: 13px;
  color: #858796;
  height: 47px;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 20px;
}

.selectdropdown {
  background: rgba(9, 19, 33, 0.0638304);
  width: 100%;
  padding: 13px;
  color: black;
  height: 47px;
  border: none;
  font-size: 13px;
  background-image: url(../img/dropdown-icon.png);
  -webkit-appearance: none;
  -moz-appearance: none;
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 20px;
}

.selectdropdown-shadow {
  width: 100%;
  padding: 10px;
  color: #000;
  border: none;
  padding: 9px 20px;
  font-family: HelveticaNeue medium;
  font-size: 16px;
  background: #e5e5e5;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  background-image: url(../img/dropdown-icon.png);
  -webkit-appearance: none;
  -moz-appearance: none;
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 18px;
}

/* .selectdropdown-shadow.width{min-width: 283px;} */

.selectdropdown-shadow.widthAccess {
  background: #ffffff;
  border: 1px solid #8b96a3;
  border-radius: 3px;
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #707985;
  width: 314px;
  background-image: url(../img/dropdown-icon.png);
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 18px;
  box-shadow: none;
  margin-bottom: 44px;
}

select.rounedtxt {
  background-image: url(../img/blue-dropdown.png);
  -webkit-appearance: none;
  -moz-appearance: none;
  background-repeat: no-repeat;
  background-position-x: 87%;
  background-size: 8px 5px;
  background-position-y: 8px;
}

.tabledropdown::after {
  display: none;
}

.textbox {
  font-size: 14px;
  background: rgba(9, 19, 33, 0.0638304);
  border-radius: 0px;
  border: none;
  color: #000;
  height: 100px !important;
}

.absoluteheader {
  position: absolute;
  width: 100%;
  z-index: 99;
}

.logo img {
  width: 180px;
  padding: 15px 0px;
}

.section-title {
  font-style: normal;
  font-size: 40px;
  line-height: 32px;
  font-family: MuseoSans bold;
  margin-bottom: 30px;
  color: #000;
}

.cta {
  background-image: url(../img/cta-vector.png);
  background-repeat: no-repeat;
  background-position: right;
  padding: 40px 0;
}

.cta_intro {
  background-image: url(../img/cta-vector.png);
  background-repeat: no-repeat;
  background-position: right;
  padding: 40px 0;
}

.cta__title {
  font-size: 50px;
  color: #000;
  line-height: 1.5;
  margin-bottom: 40px;
  font-family: MuseoSans bold;
  text-align: justify left;
}

.cta__title_gap {
  font-size: 24px;
  color: #000;
  line-height: 1.5;
  margin-bottom: 40px;
  font-family: MuseoSans bold;
  text-align: justify left;
}

.fundever_bodytxt {
  color: #000;
  line-height: 1.5;
  margin-bottom: 40px;
  font-family: MuseoSans;
  text-align: left;
}

.introduction_text_right {
  color: #000;
  line-height: 1.5;
  margin-bottom: 40px;
  font-family: MuseoSans;
  text-align: right;
}

.introduction_text_right .learnMoreText {
  color: #19456b;
  font-family: MuseoSans bold;
}

.fundever_bodytxt strong {
  font-family: MuseoSans bold;
}

.fundever_bodytxt .learnMoreText {
  color: #19456b;
  font-family: MuseoSans bold;
}

.cta__button--success {
  padding: 16px 20px;
  border: 1px solid #0f669e;
  border-radius: 5px;
  display: block;
  width: 200px;
  font-size: 18px;
  color: #0f669e;
  font-family: MuseoSans bold;
  margin: 0 auto;
  margin-bottom: 20px;
}

.cta__button--success:hover {
  background: #0f669e;
  color: #fff;
  text-decoration: none;
}

.introduction_container {
  display: inline-flex;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.introduction_container .row {
  flex-wrap: nowrap;
}

.how_works_container {
  content: "";
  clear: both;
  display: table;
  justify-content: center;
}

.howItWorksImage {
  float: left;
  width: 50%;
}

.howItWorksImage_left {
  float: left;
  width: 50%;
}

.howItWorksImage_right {
  float: right;
  width: 50%;
}

.how_works {
  width: 100%;
  height: auto;
}

.howItWorks {
  float: left;
  width: 50%;
  padding: 4% 8% 0 3%;
}

.howItWorks span {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  /* Dark – #000000 */

  color: rgba(0, 0, 0, 0.8);
}

.work_steps {
  width: 100%;
  height: 100%;

  display: block;
}

.work_steps span {
  display: block;
  font-style: bold;
  font-weight: bolder;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 1);
}

.work_steps h6 {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.8);
}

.work_steps_lender {
  width: 100%;
  height: 100%;
  font-family: MuseoSans;
  color: #000;
  text-align: justify left;
  display: block;
  font-size: 24px;
}

.work_steps_lender strong {
  font-family: MuseoSans bold;
  color: #19456b;
}

.work_steps_lender span {
  display: block;
  font-style: bold;
  font-weight: bolder;
  font-size: 26px;
  line-height: 30px;
  letter-spacing: 1px;
  margin: 25px 0 10px 0;
  color: #19456b;
}

.work_steps_lender h6 {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.6);
}

.work_steps_broker h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 50px;
  color: #19456b;
}

.work_steps_lender h1 {
  font-family: MuseoSans bold;
  font-size: 50px;
  line-height: 50px;
  color: #449aa4
}

@media screen and (max-width: 1370px) {
  .work_steps_lender span {
    font-size: 22px;
    line-height: 27px;
  }

  .work_steps_lender h6 {
    font-size: 18px;
    line-height: 23px;
  }

  .stepImage {
    width: 30px;
    height: auto;
  }
}

@media screen and (max-width: 600px) {
  .work_steps_lender span {
    font-size: 14px;
    line-height: 28px;
    margin: 0;
  }

  .work_steps_lender h6 {
    font-size: 12px;
    line-height: 22px;
  }

  .work_steps_lender h1 {
    font-size: 30px;
  }

  .stepImage {
    width: 27px;
    height: auto;
    padding-bottom: 10px;
  }

  .howItWorks {
    padding: 0;
  }

  header .container {
    max-width: 100%;
  }
}

.works__card {
  background: #ffffff;
  box-shadow: 0px 4px 25px #0085db;
  border-radius: 11px;
  padding: 20px;
}

.work__img--div {
  text-align: center;
  padding: 36px 20px;
  margin-bottom: 20px;
  background: rgba(15, 102, 158, 0.22);
}

.works__card h4 {
  font-style: normal;
  font-weight: 100;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.2px;
  color: #0f669e;
  font-family: HelveticaNeue medium;
}

.works__card p {
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  color: #0f669e;
  mix-blend-mode: normal;
  line-height: 1.5;
  opacity: 0.75;
}

.works .section-title {
  color: #fff;
}

.fundevertxt {
  padding: 40px 0px;
  padding-bottom: 80px;
}

.fundevertxt h4 {
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  color: rgba(0, 0, 0, .8);
  font-family: MuseoSans bold;
}

.fundevertxt .fundevertxt__primarytxt {
  font-size: 22px;
  line-height: 32px;
  text-align: center;
  color: #0f669e;
  margin-bottom: 40px;
  font-family: MuseoSans bold;
}

.proposedFee {
  color: black;
  font-size: 14px;
}

.fundevertxt .fundevertxt__primarytxtlarge {
  font-size: 30px;
  line-height: 32px;
  text-align: center;
  color: #0f669e;
  margin-bottom: 40px;
  font-family: MuseoSans;
}

.fundevertxt__button {
  background: #0f669e;
  color: #fff;
  padding: 10px 20px;
  border: 1px solid #0f669e;
  font-family: MuseoSans bold;
  font-size: 16px;
}


.fundevertxt__button_general {
  background: #fff;
  color: #0f669e;
  padding: 10px 20px;
  border: 1px solid #0f669e;
  font-family: MuseoSans bold;
  font-size: 16px;
}


.fundevertxt__button:hover {
  color: #0f669e;
  background: transparent;
  text-decoration: none;
}

.primary__text {
  font-family: HelveticaNeue medium;
  color: #0f669e;
}

.sendbtn-wrapper {
  width: 100px;
}

.sendbtn {
  border: none;
  background: url(/static/media/Send.0859c9b0.svg) -13px 0px no-repeat;
  width: 50px;
  height: 50px;
}

.deals {
  background: url(../img/vector-2.png);
  padding: 60px 0px;
  background-repeat: no-repeat;
}

.deals .col-md-3 {
  max-width: 22%;
}

.oppo-details {
  position: relative;
}

.oppo-details .filter_button {
  position: absolute;
  right: 12px;
  top: -10px;
  z-index: 100;
}

.deals__card {
  background: #ffffff;
  /* box-shadow: 0 5px 6px -6px #777; */
  border-radius: 4px;
  margin-bottom: 25px;
  margin-left: 10px;
  margin-right: 10px;
  /* max-height: 170px; */
}

.deals .deal__description .inlineul,
.deals .deals__card table th {
  padding: 0;
}

.deals__card.marketUpdated {
  min-height: 180px;
}

.deals__card.height-deal {
  max-height: 100%;
  width: 100%;
}

.deals__card .dealsImg {
  width: 100% !important;
  height: 190px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  object-fit: cover;
}

.deals__card1 {
  background: #ffffff;
  box-shadow: 0 5px 6px -6px #777;
  border-radius: 5px !important;
  margin-bottom: 25px;
  /* max-height: 170px; */
}

.deals__card1 img {
  width: 100% !important;
  height: 180px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  object-fit: cover;
}

.deal__description {
  padding: 20px 0px 0px;
}

.deal__header {
  overflow: hidden;
  position: relative;
}

.deal__gap_avatar {
  padding: 0px 50px 0px 10px;
}

.deal__header .request-button {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 50;
}

.deal__description p,
.deal__description .heading {
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.8);
  opacity: 0.6;
  font-weight: 600;
  margin-bottom: 10px;
}

.deal__description .desc {
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.8);
  /* opacity: 0.6; */
  /* font-weight: 600; */
  margin-bottom: 10px;
}

.deal__description .deals__primarytxt {
  font-family: HelveticaNeue medium;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 32px;
  letter-spacing: 2px;
  color: #0f669e;
  margin-bottom: 20px;
}

.deal-box {
  padding: 0 10px;
  border-radius: 5px;
}

.deal-box .dealsImg {
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
}

.deals table {
  font-size: 14px;
  width: 100%;
  font-weight: 600;
}

.deals table td {
  color: rgba(0, 0, 0, 0.8);
}

.deals table th {
  padding-left: 33px;
  margin-bottom: 5px;
  color: rgba(0, 0, 0, 0.8);
  opacity: 1;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
}

.deal__footer {
  color: rgba(0, 0, 0, 0.8);
}

.deal__footer .card {
  border: none;
}

.deal__footer p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  -webkit-box-orient: vertical;
}

.deal__footer h4 {
  opacity: 0.6;
  font-size: 14px;
}

.loginbtn {
  padding-left: 20px;
}

.loginbtn a {
  border: 1px solid #fff;
  display: block;
  width: 100px;
  text-align: center;
}

.customdatepicker {
  font-size: 0.8rem;
  border-radius: 0rem;
  background: rgba(9, 19, 33, 0.0638304);
  padding: 1.5rem 1rem;
  border: none;
}

.datepicker:after {
  content: url(../img/datepicker.png);
  position: absolute;
  right: 30px;
  top: 44px;
}

.contact-from .form-control {
  background: rgba(9, 19, 33, 0.0638304);
  background-blend-mode: multiply;
  border: none;
  border-radius: 0px;
  padding: 10px 14px;
  height: 44px;
  display: block;
}

.contact-from textarea {
  background: rgba(9, 19, 33, 0.0638304);
  background-blend-mode: multiply;
  border: none;
  border-radius: 0px;
  padding: 10px 14px;
  height: 144px;
  width: 100%;
  display: block;
}

.contact-from label {
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  font-family: HelveticaNeue medium;
}

.contact-from button {
  background: #0f669e;
  color: #fff;
  width: 100%;
  border: 0px;
  padding: 9px;
  margin-top: 25px;
  font-size: 16px;
  margin-bottom: 10px;
  font-family: HelveticaNeue medium;
}

/*custome select*/

.custome-select select {
  padding: 4px;
  margin: 0;
  background: transparent;
  color: #888;
  border: none;
  outline: none;
  display: inline-block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  width: 150px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  font-family: HelveticaNeue medium;
  font-size: 12px;
}

/* Targetting Webkit browsers only. FF will show the dropdown arrow with so much padding. */

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .custome-select select {
    padding-right: 18px;
  }
}

.custome-select {
  position: relative;
}

.custome-select:after {
  content: "\f078";
  font-family: "FontAwesome";
  font-size: 11px;
  color: #aaa;
  right: 8px;
  top: 7px;
  padding: 0 0 2px;
  position: absolute;
  pointer-events: none;
}

.custome-select:before {
  content: "";
  right: 4px;
  top: 0px;
  width: 23px;
  height: 18px;
  /* background:#fff; */
  position: absolute;
  pointer-events: none;
  display: block;
}

.mem-title {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 20px;
  color: #000000;
  font-family: HelveticaNeue medium;
}

.bold {
  font-family: HelveticaNeue medium;
}

.show__address {
  padding: 10px 0;
}

.show__address a {
  display: block;
}

.show__address .blacktxt {
  font-size: 14px;
  line-height: 38px;
  color: #000 !important;
  font-family: HelveticaNeue medium;
  line-height: 1.7;
}

/*datepicker*/

.flatpickr-day {
  color: #000;
}

span.flatpickr-weekday {
  color: #000;
}

.flatpickr-current-month span.cur-month {
  color: #000;
}

.flatpickr-current-month input.cur-year {
  color: #000;
}

.flatpickr-calendar {
  background: #fff;
  box-shadow: none;
  border: 2px solid #fafcff;
}

.flatpickr-month {
  fill: #000;
}

.flatpickr-month {
  background: #fafcff;
  height: 40px;
}

/*end datepicker*/

/*stepper*/

.stepper {
  width: 100%;
  box-shadow: 0px 4px 4px rgba(79, 79, 79, 0.25);
  background: #fff;
  padding: 0px;
  margin-bottom: 0px;
}

.stepper ul {
  width: 100%;
  display: -webkit-inline-box;
  width: 100%;
  padding-left: 0px;
  list-style-type: none;
  margin-bottom: 0px;
}

.stepper ul li {
  width: 20%;
  text-align: center;
  padding: 10px;
}

.stepper ul .active {
  background: rgba(15, 102, 158, 0.18);
}

.stepper .edit-opportunity .number {
  width: 30px;
  height: 30px;
  padding: 6px;
  font-size: 12px;
  margin: 10px auto;
  background: #0f669e;
  color: white;
  font-family: HelveticaNeue medium;
  border-radius: 100px;
}

.stepper .edit-opportunity .title {
  font-size: 14px;
  font-family: HelveticaNeue medium;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #0f669e;
}

.stepper .edit-opportunity .description {
  font-size: 12px;
  line-height: 19px;
  text-align: center;
  color: #979797;
}

.stepper .nav {
  flex-wrap: nowrap;
  overflow-x: auto;
}

.stepper .nav::-webkit-scrollbar {
  display: none;
}

.accordion {
  width: 100%;
}

.card-accordion .card {
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(118, 118, 118, 0.25);
  border-radius: 2px 2px 0px 0px;
  border: none;
  color: #000;
}

footer {
  background: #0f669e;
  padding: 30px 0;
  width: 100%;
}

footer .social-icon {
  display: inline-flex;
  list-style: none;
  color: #fff;
  padding-left: 0px;
}

footer .social-icon li {
  padding: 5px 7px;
}

footer .social-icon li a {
  color: #fff;
  font-size: 15px;
}

footer .links {
  display: inline-flex;
  list-style: none;
  color: #fff;
  padding-left: 0px;
  font-family: HelveticaNeue medium;
}

footer .links li {
  padding: 5px 23px;
}

footer .links li a {
  color: #fff;
  font-size: 15px;
}

footer .copyright {
  display: inline-flex;
  list-style: none;
  color: #fff;
  padding-left: 0px;
  margin-bottom: 0px;
}

footer .copyright li {
  padding: 5px 8px;
}

footer .copyright li a {
  color: #fff;
  font-size: 14px;
  opacity: 0.7;
}

/*faq*/

.faq-heading {
  /* text-indent: 20px; */
  color: #000;
  font-size: 16px;
  line-height: 36px;
  font-weight: 100;
  font-family: HelveticaNeue medium;
  padding: 11px 35px 0 20px;
  position: relative;
}

.extraPadding {
  line-height: 1.5 !important;
}

.faq-text {
  font-weight: 400;
  color: #000;
  width: 95%;
  line-height: 1.5;
  font-size: 15px;
  padding-left: 20px;
  margin-bottom: 30px;
}

.faq {
  margin: 0 auto;
  background: white;
  border-radius: 4px;
  position: relative;
}

.faq label {
  display: block;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  height: 56px;
  padding-top: 1px;
  background-color: #fafafa;
  border-bottom: 1px solid #e1e1e1;
}

.faq label .extraHeight {

  height: 70px !important;

}



.faq input[type="checkbox"] {
  display: none;
}

.faq .faq-arrow {
  width: 10px;
  height: 10px;
  transition: -webkit-transform 0.8s;
  transition: transform 0.8s;
  transition: transform 0.8s, -webkit-transform 0.8s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  border-top: 2px solid rgba(0, 0, 0, 0.99);
  border-right: 2px solid rgba(0, 0, 0, 0.99);
  float: right;
  position: absolute;
  top: 22px;
  right: 26px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.faq input[type="checkbox"]:checked+label>.faq-arrow {
  transition: -webkit-transform 0.8s;
  transition: transform 0.8s;
  transition: transform 0.8s, -webkit-transform 0.8s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

.faq input[type="checkbox"]:checked+label {
  display: block;
  background: rgba(255, 255, 255, 255) !important;
  color: #4f7351;
  height: auto;
  transition: height 0.8s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}

.faq input[type="checkbox"]:not(:checked)+label {
  display: block;
  transition: height 0.8s;
  height: 56px;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  background: #ffffff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  border: none;
  margin-bottom: 14px;
}

.faq input[type="checkbox"]:not(:checked)+label.moreWidth {
  display: block;
  transition: height 0.8s;
  height: 70px;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  background: #ffffff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  border: none;
  margin-bottom: 14px;
}

.subtitle {
  font-size: 24px;
  line-height: 32px;
  /* font-weight: bold; */
  align-items: center;
  text-align: center;
  color: #0f669e;
  margin-bottom: 25px;
  font-family: HelveticaNeue bold;
}

.card p {
  color: rgba(0, 0, 0, 0.8);
}

.solidbtn {
  font-size: 1rem;
  font-family: HelveticaNeue medium;
  padding: 0.75rem 4rem;
  border-radius: 0px;
  background: #0f669e;
  color: #fff;
  cursor: pointer;
  border: 2px solid #0f669e;
}

.contactbtn {
  font-size: 1rem;
  font-family: HelveticaNeue medium;
  padding: 0.5rem 2rem;
  border-radius: 5px;
  background: #fff;
  color: #0f669e;
  cursor: pointer;
  border: 2px solid #0f669e;
}

.pendingUpdateValue {
  color: green;
  font-weight: 700;
}

.pendingPreviousValue {
  color: red;
  font-weight: 700;
}

.solidbtn:hover {
  background: transparent;
  color: #0f669e;
}

.rejectbtn2 {
  font-size: 1rem;
  font-family: HelveticaNeue medium;
  padding: 0.75rem 4rem;
  border-radius: 0px;
  background: #d12d17;
  color: #fff;
  cursor: pointer;
  border: 2px solid #d12d17;
}

.rejectbtn2:hover {
  background: transparent;
  color: #d12d17;
}

.solidbtnxs {
  font-size: 14px;
  font-family: HelveticaNeue medium;
  padding: 0.35rem 2rem;
  border-radius: 0px;
  background: #0f669e;
  color: #fff;
  border: 2px solid #0f669e;
}

.solidbtnxs:hover {
  color: #fff;
  background: #0f669e;
}

.popuptxt h4 {
  font-family: HelveticaNeue medium;
  color: #000;
  font-size: 24px;
  line-height: 32px;
}

.popuptxt p {
  margin: 10px;
  margin-bottom: 40px;
  /* font-family: Helvetica Neue; */
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: rgba(0, 0, 0, 0.8);
}

.popuptxt .centertxtbold {
  padding: 20px;
  margin-bottom: 20px;
  /* font-family: Helvetica Neue; */
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: rgba(0, 0, 0, 0.8);
}

@media (min-width: 320px) and (max-width: 767px) {
  .popuptxt .centertxtbold {
    /* font-family: Helvetica Neue; */
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.1px;
    color: rgba(0, 0, 0, 0.8);
  }
}

.field-icon {
  float: right;
  margin-left: -25px;
  margin-top: -31px;
  margin-right: 120px;
  position: relative;
  z-index: 2;
}

.field-icon2 {
  float: right;
  margin-left: -25px;
  margin-top: -31px;
  margin-right: 20px;
  position: relative;
  z-index: 2;
}

.field-icon2password {
  float: right;
  margin-left: -25px;
  margin-top: -37px;
  margin-right: 20px;
  position: relative;
  z-index: 2;
}

.forgettxt {
  font-size: 12px;
  color: #000;
}

.loginbackbtn {
  color: #0f669e;
  margin-bottom: 25px;
  font-family: HelveticaNeue medium;
  margin: 15px;
}

.anchorbtn {
  font-family: HelveticaNeue medium;
  display: block;
  margin: 13px 0;
  margin-bottom: 29px;
  font-size: 15px;
}

.bluebg {
  background: #0f669e;
}

.blue {
  color: #0f669e;
}

.aqua {
  color: #449aa4
}

.darkBlue {
  color: #19456b
}

.learnMoreText {
  font-style: MuseoSans bold;
  color: #2b6198;
}

.sidebar-dark .sidebar-brand {
  background: #0f669e;
}

.blueheader .navbar-light .navbar-nav .nav-link {
  color: #fff;
}

.bg-white {
  background: #fff;
}

.sidebar {
  box-shadow: 1px 2px 5px #e6e6e6;
  background-color: white;
  position: relative;
  z-index: 90;
}

.sidebar-dark .sidebar-heading {
  color: #778ca2;
}

.sidebar-dark .nav-item .nav-link {
  color: #778ca2;
  text-align: center;
}

.sidebar .nav-item .nav-link {
  padding: 0.6rem;
}

.sidebar.minimized {
  width: 4.5rem;
}

.sidebar.minimized .nav-item .nav-link {
  padding: 1rem 0.5rem 1rem 1rem !important;
  padding-left: 12px !important;
}

.loginuser {
  padding: 14px 0 13px 25px;
}

.loginuser h4 {
  color: #0f669e;
  font-size: 14px;
  font-family: HelveticaNeue medium;
  text-transform: capitalize;
}

.loginuser p {
  color: #0f669e;
  font-size: 12px;
  margin-bottom: 0px;
  text-transform: uppercase;
}

.sidebar .active .nav-link {
  background: #0f669e;
  color: #fff;
}

.sidebar.minimized .active .nav-link {
  /* background: none; */
  width: 100%;
}

.pagetitle-header {
  padding: 10px 0 2px 0;
  background: #fff;
  box-shadow: 2px 2px 2px #e6e6e6;
  position: relative;
  z-index: 100;
}

.pagetitle h1 {
  text-transform: capitalize;
  display: inline-block;
  font-family: Helvetica Neue Bold;
  font-style: normal;
  /* font-weight: bold; */
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.1px;
  color: rgba(0, 0, 0, 0.8);
  margin: 0;
}

.pagetitle img {
  margin-top: -8px;
}

.my-profile {
  font-size: 22px;
  line-height: 32px;
  color: #000000;
}

.pagetitle .viewAllTitle {
  display: inline-block;
  padding: 10px 0;
}

.viewAllChevron {
  color: rgba(0, 0, 0, 0.8);
}

.graybg {
  background: #f3f3f3;
  min-height: 93vh !important;
}

.sidebar-dark .nav-item .nav-link {
  padding: 1rem 0.5rem 1rem 2rem !important;
  text-align: left;
  text-transform: uppercase;
}

.card-description {
  text-align: center;
  padding: 4rem 0.5px;
  /* min-height: 360px; */
  min-height: 200px;
}

.card-description h4 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  font-family: HelveticaNeue medium;
}

.graytitle {
  font-size: 18px;
  line-height: 22px;
  font-family: HelveticaNeue medium;
  color: #778ca2;
}

.graytitle3 {
  font-size: 18px;
  line-height: 22px;
  font-family: HelveticaNeue medium;
  color: #000;
}

.graytitle.dashborad {
  font-family: HelveticaNeue;
}

.graytitle.changed {
  color: rgba(0, 0, 0, 0.8);
}

.gray-title.document {
  font-size: 16px;
}

.card-description p {
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #98a9bc;
  padding: 20px;
  /* font-family: Helvetica Neue; */
}

.buttonBelowText {
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  /* font-family: Helvetica Neue; */
}

.paddingSetting {
  padding-bottom: 15px !important;
}

.pad-all-10 {
  padding: 10px !important;
}

.width-100 {
  width: 100%;
}

.updates-div h3 {
  font-size: 18px;
  line-height: 1.5;
  font-family: HelveticaNeue medium;
  padding: 9px 0;
  color: #000000;
}

.opportunity-description .rightbtn {
  font-size: 13px;
}

.minheight617 {
  min-height: 617px;
  max-height: 617px;
  overflow-x: auto;
}

.dashfooter {
  padding: 10px !important;
}

.dashfooter .links {
  margin-bottom: 0px;
}

.dashfooter .col-md-4 {
  align-self: center;
}

.dashfooter .copyright {
  font-size: 14px;
}

.dashfooter .links li a {
  font-family: HelveticaNeue;
}

.card-body .opportunity-description:last-child {
  border-bottom: none;
}

.dashboard .card-body {
  height: 635px;
}

.dashboard .card-body .card-description.spacer {
  padding: 10rem 0.5px;
}

.rightbtn {
  font-family: HelveticaNeue medium;
  font-size: 14px;
  position: absolute;
  top: 27px;
  right: 20px;
  color: #0f669e;
}

.rightbtn.lender-invite {
  font-family: HelveticaNeue;
  font-size: 18px;
  top: 13px;
}

.rightbtn.disabled {
  color: #98a9bc;
}

.rightbtn img {
  width: 23px;
  margin-right: 5px;
  margin-bottom: 2px;
}

.opportunity-description .rightbtn {
  top: 10px;
}

.updates-div h4 {
  font-size: 18px;
  line-height: 22px;
  color: #778ca2;
  margin-bottom: 20px;
}

.updates-div {
  margin-bottom: 70px;
}

.updates-div {
  font-size: 14px;
  color: #000;
}

.profile-img {
  width: 91px;
  height: 91px;
  position: relative;
  margin: 0 auto;
  margin-bottom: 21px;
  border-radius: 100px;
  overflow: hidden;
}

.profile-img img {
  width: 100%;
  border-radius: 100px;
  height: inherit;
}

/* .heightInherit{
	height: inherit;
} */

.profile-img .editbtn {
  color: #0f669e;
  font-size: 13px;
  font-family: HelveticaNeue medium;
  /* background: rgba(196, 196, 196, 0.4); */
  padding: 1px 26px;
  position: absolute;
  bottom: 0px;
  overflow: hidden;
  left: 7px;
  border-bottom-left-radius: 27px;
  border-bottom-right-radius: 28px;
  background-color: #d5d5d5;
}

#edit-profile .preview-sec .previewList {
  list-style: none;
  width: 40%;
  height: 40%;
  margin: 0 auto;
}

.skip-button {
  font-family: HelveticaNeue medium;
  text-align: center;
  font-size: 14px;
  margin: 0 auto;
  display: block;
  margin-top: 10px;
  text-decoration: underline;
}

.primaryanchor {
  font-family: HelveticaNeue medium;
  text-align: center;
  font-size: 14px;
  margin-top: 10px;
  text-decoration: underline;
}

.primaryanchor.see-more,
.blue-text {
  font-style: normal;
  font-size: 16px;
  line-height: 32px;
  text-align: right;
  letter-spacing: -0.1px;
  color: #0f669e;
}

.blue-text.view {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  text-transform: uppercase;
  color: #0f669e;
}

/*drag and drop*/

.attachment-title {
  margin: 35px auto 0;
}

.attachment-title .cat-title {
  padding: 0.36rem 0.5rem;
  margin: 0;
  display: inline-block;
}

.attachment-title input {
  border-radius: 0;
  background: rgba(9, 19, 33, 0.0638304);
  padding: 0.35rem 0.5rem;
  border: none;
  color: #000;
}

.attachment-title input[type="text"]:focus {
  background: rgba(9, 19, 33, 0.0638304);
  box-shadow: 0 0 2px rgb(199 233 255);
  outline: 0 none;
}

.uploadOuter {
  text-align: center;
  padding: 0px !important;
}

.uploadOuter span.browse {
  text-decoration: underline;
}

.uploadOuter strong {
  padding: 0 10px;
}

.uploadOuter .dragBox {
  width: 80%;
  height: 130px;
  margin: 10px auto;
  position: relative;
  text-align: center;
  font-family: HelveticaNeue medium;
  line-height: 1.7;
  color: #999;
  text-transform: uppercase;
  display: block;
  transition: transform 0.3s;
  font-size: 12px;
  background: #f5f5f5;
  border: 1px dashed #e8ecef;
  border-radius: 4px;
}

.borderlessbottom {
  border-bottom: none;
}

.dragimgicon {
  font-size: 28px;
  margin-bottom: 5px;
  margin-top: 30px;
}

.uploadOuter input[type="file"] {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  top: 0;
  left: 0;
}

.uploadOuter .browse {
  color: #0f669e;
  text-decoration: none;
  background-color: transparent;
}

.draging {
  transform: scale(1.1);
}

.draging #preview {
  text-align: center;
}

#preview img {
  max-width: 100%;
}

.draging img {
  max-width: 100%;
}

.opportunity-description {
  border-bottom: 1px solid #d0d0d0;
  padding: 12px;
  margin-bottom: 5px;
  position: relative;
  min-height: 118px;
}

.opportunity-description h3 {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  font-family: HelveticaNeue medium;
  color: black;
}

.opportunity-description h3 span {
  font-size: 12px;
  line-height: 14px;
  margin-left: 20px;
  text-align: right;
  text-transform: capitalize;
  color: #707070;
}

.opportunity-description p {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 22px;
  color: #8697a8;
  margin-bottom: 5px;
}

.pagetitle h1 span i {
  font-size: 25px;
  margin-right: 15px;
}

.tabs-bg {
  background: #fff;
  box-shadow: 2px 2px 2px #e6e6e6;
  position: relative;
  z-index: 100;
}

.tabs-bg.fullwidthtab,
.twofullwidthtab {
  box-shadow: none;
}

.tabs-bg .nav-link {
  color: #adadad !important;
  font-size: 16px;
}

.tabs-bg .nav-tabs .nav-item {
  min-width: 20%;
  text-align: center;
}

.tabs-bg .nav-tabs .nav-link.active {
  color: #0f669e !important;
  border: none;
  border-bottom: 2px solid #0f669e;
}

.pad-all-20 {
  padding: 28px 20px 20px 20px;
}

.opp-detail {
  display: inline-flex;
  width: 100%;
  position: relative;
}

.opp-detail .icon {
  width: 70px;
}

.opp-detail .icon img {
  /* border: 3px solid #0f669e61; */
  border-radius: 100px;
  width: 86%;
}

.opp-detail .totalviews {
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #98a9bc;
  /* width: 110px; */
  margin-top: 6px;
}

.opp-detail .totalviews.card-width {
  width: 100%;
  padding-left: 20px;
}

.opp-detail .totalviews h4 {
  font-size: 20px;
  line-height: 1.1;
  margin-bottom: 5px;
  color: #000;
  font-family: HelveticaNeue medium;
}

.opp-detail .totalviews p {
  margin-bottom: 0px;
  line-height: 10px;
}

.opp-detail .detailicon {
  font-size: 25px;
  color: #000;
  position: absolute;
  right: 10px;
  top: 13%;
}

.transparent-bg {
  background: transparent;
}

.fullwidthtab {
  background: transparent;
}

.fullwidthtab .collasible-table th {
  padding-left: 36px;
}

.nav-tabs .nav-link {
  border: 0;
  border-bottom: 2px solid transparent;
}

.nav-tabs.nav-border,
.nav-tabs.nav-border .nav-link {
  border-bottom: 2px solid transparent;
}

.nav-tabs .nav-link:hover {
  border-bottom: 2px solid #0f669e;
  color: #0f669e !important;
}

.twofullwidthtab .nav-tabs .nav-item {
  min-width: 50%;
}

.add-principal {
  border: 1px dashed #979797;
  text-align: center;
  padding: 58px 0px;
}

.footerlogo {
  width: 100%;
}

.gray-title {
  font-size: 18px;
  margin-bottom: 20px;
  font-family: HelveticaNeue;
}

.address-div {
  background: #fff;
  padding: 10px;
  font-family: HelveticaNeue medium;
  font-size: 14px;
  color: #000;
}

.summary-div {
  /* position: relative; */
  /* margin: 20px 0; */
}

.summary-div .detailbtn {
  /* position: absolute;
	right: 0px;
	top: 0px; */
  font-family: HelveticaNeue medium;
  text-decoration: underline;
}

.summary-div .editbtn {
  /* position: absolute; */
  font-family: HelveticaNeue medium;
  /* right: 96px;
	top: 0px; */
  text-decoration: underline;
}

.link {
  font-family: Helvetica Neue;
  text-decoration: none;
  color: #0F669E;
  cursor: pointer;
}


.sameWidthHeight {
  width: 190px;
  height: 45px;
}

.font-600 {
  font-family: HelveticaNeue medium;
}

.card-14 {
  font-size: 14px;
}

.summary-description p {
  color: rgba(0, 0, 0, 1);
  text-transform: capitalize;
}

.selfalign {
  align-self: center;
}

.document-list {
  /* font-family: HelveticaNeue medium; */
  max-height: 100vh;
  max-height: 81vh;
  overflow-x: auto;
}

.document-list h4 {
  font-size: 16px;
  line-height: 19px;
  color: #252631;
  /* font-family: HelveticaNeue; */
  text-transform: capitalize;
  font-weight: normal;
}

.document-list p {
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #98a9bc;
  margin-bottom: 0px;
}

.document-list .card {
  /* margin-bottom: 15px; */
  margin: 0 17px 15px 0;
}

.document-list .downloadbtn {
  font-size: 14px;
  line-height: 21px;
  text-decoration-line: underline;
  color: #0f669e;
}

/*collapsible table*/

.collasible-table {
  border-collapse: separate;
  border-spacing: 0 1em;
  width: 100%;
}

.viewAll-bg .collasible-table {
  border-collapse: collapse;
}

.viewAll-bg .collasible-table .table-row {
  background: transparent;
  box-shadow: none;
  border-radius: 0;
  color: #252631;
  border-bottom: 1px solid #f2f4f6;
}

.viewAll-bg .collasible-table .table-row:first-child {
  border-top: 1px solid #f2f4f6;
}

.viewAll-bg .collasible-table .table-row:last-child {
  border-bottom: 0;
}

.collasible-table .table-row {
  background: #ffffff;
  box-shadow: 0px 2px 14px rgba(102, 102, 102, 0.21);
  border-radius: 3px !important;
  color: #44566c;
}

.collasible-table th {
  font-weight: 100;
}

.viewAll-bg .collasible-table th {
  padding-bottom: 7px;
}

.font-13 {
  font-size: 13px;
}

.collasible-table .table-row td,
.collasible-table .table-row .table-col {
  padding: 10px;
  font-size: 15px;
  position: relative;
  padding-right: 25px;
  padding-left: 35px;
  text-transform: capitalize;
  margin: auto;
}

@media (min-width: 481px) and (max-width: 767px) {

  .collasible-table .table-row td,
  .collasible-table .table-row .table-col {
    font-size: 11px !important;
  }
}

.collasible-table .namedetail {
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  /* display: flex;
align-items: center; */
  text-transform: capitalize;
  /* Body/Text */
  color: #44566c;
}

.collasible-table .namedetail img,
.namedetail img.imgCircle {
  width: 39px;
  height: 39px;
  border-radius: 100px;
  margin-right: 10px;
}

.approvebtn {
  background: #0f669e;
  border: 2px solid #0f669e;
  color: #fff;
  font-size: 15px;
  margin-right: 16px;
  /* border: none; */
  border-radius: 5px;
  padding: 8px 13px;
  font-family: HelveticaNeue medium;
}

.approvebtn.message {
  width: 128px;
}

.approvebtn:disabled,
.primarybtn:disabled,
.solidbtn:disabled,
.forNow .solidbtn {
  background: #98a9bc;
  border: 2px solid #98a9bc;
}

.primarybtn:disabled:hover,
.solidbtn:disabled:hover,
.forNow .solidbtn {
  background: #98a9bc;
  border: 2px solid #98a9bc;
  color: #fff;
}

.rejectbtn:disabled,
.borderbtn:disabled {
  border-color: #98a9bc;
  color: #98a9bc;
}

.approve-padding {
  padding: 8px 35px;
  background: #13bd24;
  border: 2px solid #13bd24;
}

.approve-padding:disabled {
  padding: 8px 35px;
  background: #13bd24;
  border: 2px solid #13bd24;
}

.rejectbtn {
  color: #0f669e;
  background: #fff;
  font-size: 15px;
  border: 2px solid #0f669e;
  border-radius: 5px;
  padding: 8px 13px;
  display: inline-block !important;
  font-family: HelveticaNeue medium;
}

.primarybtn {
  background: #0f669e;
  color: #fff;
  font-size: 15px;
  border: none;
  padding: 8px 20px;
  border: 2px solid #0f669e;
  font-family: HelveticaNeue medium;
}

.pending-approvebtn {
  background: #0f669e;
  color: white;
  font-size: 14px;
  padding: 5px 5px 5px 5px;
  border: none;
  font-family: HelveticaNeue medium;
}

.pending-rejectbtn {
  color: #0f669e;
  background: #fff;
  font-size: 14px;
  border: 1px solid #0f669e;
  border-radius: 5px;
  padding: 5px 5px 5px 5px;
  display: inline-block !important;
  font-family: HelveticaNeue medium;
}

.deletebtn {
  background: red;
  color: #fff;
  font-size: 15px;
  border: none;
  padding: 8px 20px;
  border: 2px solid red;
  font-family: HelveticaNeue medium;
}

.primarybtn.disable-btn {
  background: #98a9bc;
  border: 2px solid #98a9bc;
}

.primarybtn.create {
  font-size: 16px;
  padding: 8px 13px;
  min-width: 250px;
  border-radius: 3px;
}

.primarybtn:hover {
  color: #fff;
  background: #0f669e;
}

.dangerbtn:hover {
  color: #fff;
}

.alldeletebg {
  background: #f5f5f5;
  padding: 34px 20px;
}

.samewidth {
  width: 150px;
}

.borderbtn {
  border: 2px solid #0f669e;
  background: #fff;
  color: #0f669e;
  padding: 8px 20px;
  font-size: 15px;
  font-family: HelveticaNeue medium;
}

.borderbtnLight {
  border: 1px solid #0f669e;
  background: #fff;
  color: #0f669e;
  padding: 8px 20px;
  font-size: 15px;
  font-family: HelveticaNeue medium;
}

.graybtn {
  background: #98a9bc;
  border: 2px solid #98a9bc;
  color: #fff;
  padding: 8px 20px;
  font-size: 15px;
  font-family: HelveticaNeue medium;
}

.borderbtn.border {
  border: 1px solid rgba(15, 102, 158, 0.58);
}

.cancelbtn {
  border: 2px solid #0f669e;
  background: #fff;
  color: #0f669e;
  padding: 8px 20px;
  font-size: 15px;
  font-family: HelveticaNeue medium;
}

@media (min-width: 320px) and (max-width: 767px) {
  .cancelbtn {
    padding: 8px 0 !important;
    text-align: center;
  }

  .primarybtn {
    width: 40%;
  }
}

.dangerbtn {
  background: #d12d17;
  color: #fff;
  font-size: 15px;
  border: none;
  padding: 8px 20px;
  border: 2px solid #d12d17;
  font-family: HelveticaNeue medium;
  line-height: 1.1rem;
}

.submitbtn {
  background: #0f669e;
  color: #fff;
  font-size: 15px;
  border: none;
  padding: 8px 20px;
  border: 2px solid #0f669e;
  font-family: HelveticaNeue medium;
  line-height: 1.1rem;
}

/* .collasible-table tr td:last-child{
    text-align: right;
} */

.idtag {
  color: #858585;
  margin-right: 10px;
  font-size: 13px;
  font-weight: 600;
}

.userTag {
  position: absolute;
  right: 0;
  color: #778ca2;
  margin-top: 15px;
  margin-right: 30px;
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 20px;
}

.userTitle {
  color: #778ca2;
  margin-top: 15px;
  margin-right: 30px;
  margin-left: 18px;
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 20px;
}

.border-r-3 {
  border-radius: 3px;
}

.pad-0 {
  padding: 0px;
}

.border-select {
  float: right;
  margin-right: 22px;
  color: #0f669e;
  background: transparent;
  padding: 3px;
  border-color: #0f669e;
  font-family: HelveticaNeue medium;
  font-size: 14px;
  border-radius: 3px;
}

/*chat conversation*/

.chat-conversation {
  background: #f5f5f5;
  padding: 10px;
  padding-top: 5px;
}

.chat-conversation .lists {
  width: 100%;
  max-height: 72vh;
  overflow-y: auto;
}

.chat-conversation .persons-list {
  border-right: 1px solid #eaedf0;
}

.chat-conversation .profileimg img {
  border-radius: 100px;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.chat-conversation .lists {
  width: 100%;
}

.chat-conversation .list {
  display: inline-flex;
  position: relative;
  border-bottom: 1px solid #eaedf0;
  padding-bottom: 10px;
  width: 100%;
  padding: 5px 10px;
  padding-top: 10px;
}

.chat-conversation .list.active {
  background: #fff;
}

.chat-conversation .list .time {
  position: absolute;
  right: 15px;
  font-size: 14px;
}

.chat-conversation .list .name {
  color: #44566c;
  font-family: HelveticaNeue medium;
  margin-bottom: 4px;
  width: 100%;
  line-height: 1.5;
  font-size: 14px;
}

.chat-conversation .list .description {
  font-size: 13px;
}

.chat-conversation .chat-person-left {
  padding-left: 10px;
}

.chat-conversation .chat-person-left {
  display: -webkit-box;
  padding: 15px 0;
  float: left;
  max-width: 100%;
  padding-left: 20px;
}

.chat-conversation .chat-person-left .message {
  align-self: center;
  color: #ffffff !important;
  background: #0081ff;
  padding: 10px;
  border-radius: 4px;
  color: #fff;
  line-height: 1.2;
  vertical-align: middle;
  display: table;
  font-size: 15px;
  border: 1px solid #0081ff;
  margin-right: 30px;
  box-shadow: 0 2px 8px 0 rgba(13, 51, 32, 0.1);
}

.chat-conversation .chat-person-left .showtime {
  color: #8080809c;
  font-size: 12px;
  /* margin-right: 10px;
    margin-top: 27px;
    margin-left: 10px; */
  margin: 27px 10px 0 10px;
}

.chat-conversation .chat-person-right {
  display: -webkit-box;
  padding: 10px 0;
  float: right;
  position: relative;
}

.chat-person-right .showtime {
  color: #8080809c;
  font-size: 12px;
  /* margin-right: 10px;
    margin-top: 27px; */
  margin: 27px 10px 0 0;
}

.main-chat {
  /* padding-right: 15px; */
}

.chat-person-right .profileimg {
  vertical-align: baseline;
  align-items: flex-end;
  display: flex;
}

.chat-person-left .profileimg {
  vertical-align: baseline;
  align-items: flex-end;
  display: flex;
}

.chat-conversation .chat-person-left .showtime {
  position: absolute;
  bottom: -13px;
  right: 0px;
}

.chat-conversation .chat-person-right .showtime {
  position: absolute;
  bottom: -18px;
}

.chat-person-right {
  margin-bottom: 20px;
  position: relative;
}

.chat-person-left {
  margin-bottom: 20px;
  position: relative;
}

.chat-scroller {
  max-height: 50vh;
  min-height: 50vh;
  width: 100%;
  overflow: hidden;
  overflow-y: scroll;
}

.sortfield label {
  font-size: 15px;
  margin-left: 5px;
  margin-top: 5px;
}

.sortfield select {
  background: transparent;
  border: none;
  font-size: 15px;
  margin-left: 10px;
}

.chat-footer {
  border-top: 1px solid #eee;
}

@media (min-width: 1200px) {
  .chat-conversation .chat-person-right .message {
    margin-right: 30px;
    max-width: 88%;
    min-width: 25%;
  }
}

.chat-conversation .chat-person-right {
  /* min-width: 45%; */
}

.emojidiv {
  padding: 10px;
  border-right: 1px solid #eee;
}

.msgwriter {
  margin-bottom: 0px;
  margin-top: 5px;
}

.msgwriter input {
  border: none;
}

.msgwriter input:focus {
  background: transparent;
}

.lender {
  position: absolute;
  background: #28b440;
  right: 14px;
  font-size: 12px;
  padding: 2px 5px;
  color: #fff;
  border-radius: 3px;
  width: 80px;
  text-align: center;
  font-family: HelveticaNeue medium;
  text-transform: capitalize;
}

.redtag {
  color: #ff3d57;
  margin-left: 4px;
  align-self: center;
  font-size: 12px;
}

.bluetag {
  color: #22cce2;
  margin-left: 4px;
  align-self: center;
  font-size: 12px;
}

.profile-images {
  display: -webkit-flex;
  margin-top: 10px;
}

.profile-images .image img {
  width: 47px;
  border-radius: 100px;
  margin-right: 8px;
}

.profile-images .image {
  position: relative;
}

.profile-images .image .shownametag {
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #858585;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  padding: 3px 8px;
  position: absolute;
  text-align: center;
  width: 101px;
  right: -90px;
  bottom: -7px;
}

.groupcard {
  height: 160px;
}

.rounedtxt {
  color: #0f669e;
  font-size: 12px;
  border: 1px solid #0f669e;
  padding: 3px 10px;
  line-height: 13px;
  border-radius: 100px;
  margin-right: 10px;
  font-weight: bold;
}

.rounedtxt:disabled {
  color: #98a9bc;
  border: 1px solid #98a9bc;
}

.rouneddropdown {
  color: #0f669e;
  font-size: 12px;
  border: 1px solid #0f669e;
  padding: 3px 10px;
  line-height: 13px;
  border-radius: 100px;
  background: transparent;
  font-weight: bold;
}

.primarytags {
  display: -webkit-flex;
  position: absolute;
  top: 42px;
  left: 275px;
}

.primarytags.oppotunity-details {
  top: 93px;
  right: 32%;
  left: auto;
  z-index: 200;
}

.primarytags.oppotunity-details .rounedtxt:focus {
  outline: none;
  box-shadow: none;
}

.primarytags.oppotunity-details .dropdown .dropdown-menu {
  padding: 0;
}

.mr-100 {
  margin-right: 100px;
}

.structureheader {
  color: #0f669e;
  font-family: HelveticaNeue medium;
  margin: 10px 0px !important;
}

.structuredata {
  color: #000;
  /* font-family: HelveticaNeue medium; */
  margin: 10px 0px !important;
  padding-bottom: 10px;
  border-bottom: 1px solid #6c757d45;
}

.structureheader .col-6 {
  align-self: center;
}

.structureheader .col-2 {
  align-self: center;
}

.structuredata .col-6 {
  align-self: center;
}

.structuredata .col-2 {
  align-self: center;
}

.structuredata a {
  text-decoration: underline;
}

.structuredata .form-control {
  background: rgba(9, 19, 33, 0.0638304);
  border: none;
}

.graypanel {
  background: #e8e8e8;
  color: #000;
  padding: 10px 0px;
  font-family: HelveticaNeue medium;
}

.graypanel p {
  margin-bottom: 0px;
}

.bluelabel {
  color: #0f669e;
  font-family: HelveticaNeue medium;
  font-weight: 100;
  font-size: 15px;
}

.width-10 {
  width: 10%;
}

.width-15 {
  width: 15%;
}

.width-20 {
  width: 20%;
}

.width-30 {
  width: 30%;
}

.width-40 {
  width: 40%;
}

.width-50 {
  width: 50%;
}

.borderbottom-none {
  border-bottom: none !important;
}

.height44 {
  height: 44px !important;
}

.height44 td {
  height: 55px;
}

.height64 td {
  height: 64px !important;
}

.custom-control-label::before {
  background-color: rgba(9, 19, 33, 0.08383);
  border-radius: 3px;
  border: 1.5px solid #d3d3d3;
  box-sizing: border-box;
  background: #fff;
  border-radius: 6px !important;
}

.cash-equity .custom-control-label::before {
  /* background-color: #fff !important;     */
  top: 0.15rem;
  left: -1.7rem;
  /* border: 1.5px solid #D3D3D3;
    box-sizing: border-box;
    background: #fff; */
  /* border-radius: 0px !important; */
}

.financials .custom-control-label::before {
  background-color: #fff !important;
  top: 0.15rem;
  left: -1.7rem;
  border: 1.5px solid #d3d3d3;
  box-sizing: border-box;
  background: #fff;
  border-radius: 0px !important;
}

.additional-equity {
  padding: 20px 0;
}

.cash-equity {
  padding: 20px 0;
}

.pad-right-26 {
  padding-right: 26px;
}

.pagetitle i {
  margin-right: 10px;
}

@media (min-width: 1200px) {
  .chat-conversation .chat-person-right .message {
    margin-right: 30px;
    max-width: 400px;
  }

  .chat-conversation .chat-person-left .message {
    max-width: 504px;
  }
}

.chat-conversation .chat-person-right .message {
  align-self: center;
  color: #00000085;
  background: #f8fafb;
  padding: 10px;
  border-radius: 4px;
  line-height: 1.2;
  vertical-align: middle;
  display: table;
  margin-right: 20px;
  font-size: 15px;
  box-shadow: 0 2px 8px 0 rgba(13, 51, 32, 0.1);
}

.searchbar span {
  position: absolute;
  top: 6px;
  left: 24px;
}

.searchbar input {
  background: transparent;
  border: none;
  padding-left: 34px;
}

.searchbargray {
  max-width: 435px;
  position: relative;
  top: -5px;
  width: 90%;
}

.searchMsg {
  max-width: 100% !important;
  width: 100% !important;
  top: 0% !important;
}

.searchMsg input {

  background-color: white !important;
  color: #0f669e;
  padding-left: 12px !important;
}

.searchbargray.close-opp {
  max-width: 100%;
  width: 100%;
  position: static;
}

.searchbargray.view {
  float: none;
}

.searchbargray span {
  position: absolute;
  top: 7px;
  left: 11px;
  transform: rotate(86deg);
  z-index: 100;
}

.searchbargray.close-opp span {
  left: 25px;
}

.searchbargray.view span {
  left: 20px;
}

.searchbargray input,
.select-search__value input {
  background: #f5f5f5;
  border: none;
  padding-left: 34px;
}

.searchbargray.floating {
  width: 309px;
}

.searchbargray.floating span {
  left: 40px;
}

.searchbargray.floating input {
  padding-left: 40px;
}

.searchClosing .searchbargray input {
  background: #ffffff;
  border: 1px solid #dfe3e9;
  box-sizing: border-box;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}

.searchClosing .divider {
  border-bottom: 1px solid #8b96a3;
}

.searchClosing .namedetail {
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #45424e;
}

.searchClosing .form-check-input {
  margin-top: 0.8rem;
}

.searchClosing .primarybtn {
  width: 100%;
}

.lenders-scroll {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}

.searchbargray.view input {
  background: #dddddd;
  border-radius: 6px;
}

.searchinputbox {
  background: #f5f5f5;
  margin-top: 4px;
  border: none;
}

.chat-conversation .chat-panel {
  background: #fff;
}

.chat-panel .chat-header {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.box-shadow {
  box-shadow: 0 10px 6px -6px #777;
}

.menu-btn {
  color: #44566c;
  font-size: 18px;
}

.no-background {
  background: none;
  padding: 0px;
}

.gray-bg {
  background: #f5f5f5;
}

.main-title {
  font-family: HelveticaNeue medium;
  font-size: 32px;
  line-height: 64px;
  margin: 27px 0;
}

.main-title.opp-heading {
  color: #4f5a68;
  /* font-family: Helvetica Neue; */
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 64px;
  letter-spacing: 0.9px;
}

.border-rad-0 {
  border-radius: 0px;
}

.card .title5 {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  font-family: HelveticaNeue medium;
  display: flex;
  color: #0f669e;
  margin-bottom: 25px;
}

.card .pending {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  font-family: HelveticaNeue medium;
  display: flex;
  color: #ff7c31;
}

.font-13 {
  font-size: 13px;
  margin: 4px;
}

.modal-title {
  color: #000000;
  font-family: HelveticaNeue medium;
  font-size: 24px;
}

.modal-title22 {
  color: #000000;
  font-family: HelveticaNeue medium;
  font-size: 22px;
}

.modal-title1 {
  color: #000000;
  font-family: HelveticaNeue medium;
  font-size: 23px;
}

.modal-content {
  border-radius: 0;
}

.modal-dialog.modal-md {
  margin: 13.75rem auto;
}

.modal-dialog.modal-lg {
  margin: 10rem auto;
}

#confirmation .confirmPadding {
  padding: 0 52px 30px 52px;
}

.paddingClose {
  margin-top: 119px;
}

#closeOpportunity label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.8);
}

#reopen .marginManage {
  margin-bottom: 74px;
}

.colorblack {
  color: black;
}

#approvalAction .publish-now div {
  margin-top: 100px;
}

#approvalAction .managePadding {
  margin: 68px 0 96px 0;
}

#approvalAction .publish-now {
  padding: 0 52px 20px 52px;
}

.msgcount {
  border-radius: 100px;
  font-size: 11px;
  width: 20px;
  height: 20px;
  font-family: HelveticaNeue medium;
  padding: 2px;
  text-align: center;
  color: #fff;
  background: #ff0000;
  font-weight: 900;
  position: absolute;
  top: 4px;
  left: 92px;
}

.network .msgcount {
  left: 55%;
}

.accordion .card-header:after {
  font-family: "FontAwesome";
  content: "\f068";
  float: right;
}

.accordion .card-header.collapsed:after {
  /* symbol for "collapsed" panels */
  content: "\f067";
}

.width-140 {
  width: 140px;
}

.underline {
  text-decoration: underline;
}

.inputtable thead tr th {
  padding-bottom: 10px;
}

.inputtable tbody tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  padding-bottom: 5px;
}

.bluecolor {
  color: #0f669e !important;
}

.totalarea {
  width: 82px;
  display: inline-block;
}

.inputtable form.user .form-control-user {
  padding: 1.2rem 1rem;
}

.divcenter {
  margin: 0 auto;
}

.section-title.hideBr br {
  display: none;
}

@media (max-width: 390px) {
  .banner__description_general p:nth-child(2) {
    padding-top: 6%;

  }
}

@media (max-width: 600px) {
  .banner__description {
    padding-top: 8px;
  }

  .banner__description_general {
    padding-top: 8px;
    margin: 0 !important;
  }

  .logo img {
    width: 120px;
    padding: 5px 0 5px 15px;
  }

  .start-header {
    padding: 0px 0;
  }

  .banner__caption {
    bottom: 24%;
  }

  .banner__caption_general {
    top: 35%;
  }

  .banner__caption_lender {
    bottom: 21%;
  }

  .banner__caption h1 {
    font-size: 20px;
    line-height: 1.5;
  }

  .banner__caption_general h1 {
    font-size: 23px !important;
    line-height: 1.4 !important;
  }

  .banner__caption_general h1 br {
    display: none;
  }

  .banner__caption_lender h1 {
    font-size: 20px;
    line-height: 1.5;
  }

  .banner__description ul li {
    padding-right: 8px;
    font-size: 20px;
  }

  .banner__description_general ul li {
    padding-right: 8px;
    padding-top: 5px;
    font-size: 18px;
  }

  .banner__description p {
    font-size: 15px;
  }

  .banner__description_general p {
    font-size: 12px;
    margin: 0 !important;
    line-height: 17px;
    padding-top: 6px;
  }

  .banner__description_general p:nth-child(2) {
    padding-top: 6%;
    font-size: 13px;
    margin: 0 !important;
    line-height: 17px;
  }

  .navbar-light .navbar-toggler-icon {
    border-bottom: 2px solid #0f669e;
  }

  .blueheader .navbar-light .navbar-toggler-icon {
    border-bottom: 2px solid #fff;
  }

  .blueheader .navbar-light .navbar-toggler-icon:after,
  .navbar-light .navbar-toggler-icon:before {
    background-color: #fff;
  }

  .blueheader .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
    border-color: transparent;
  }

  .absoluteheader {
    position: relative;
    width: 100%;
    padding: 8px 0;
  }

  .start-header.scroll-on {
    background: #fff;
  }

  .section-title {
    font-size: 30px;
  }

  .cta__title {
    font-size: 20px;
  }

  .cta__title br {
    display: none;
  }

  .mobileAlignLeft {
    text-align: left;
    padding-left: 10px;
  }

  .mobileAlignLeft .generalCardImage2 {
    position: relative;
  }

  .generalCardInfo2 span {
    padding-left: 10px !important;
    padding-top: 15px;
  }

  .generalCardInfo2 h5 {
    padding-left: 10px !important;
    font-family: MuseoSans;
  }

  .mobileGridAdjust .col-sm-6 {
    width: 50%;
  }

  .section-title.hideBr br {
    display: block;
  }


  .works__card {
    margin-bottom: 10px;
  }

  .deals .col-md-3 {
    max-width: 100%;
  }

  .deal__description .deals__primarytxt {
    font-size: 30px;
  }

  .mobileGridFooter {
    text-align: center;
  }

  .mobileGridFooter ul {}

  .deal__description {
    padding: 10px 15px;
  }

  .fundevertxt .fundevertxt__primarytxt {
    font-size: 25px;
    line-height: 1.5;
  }

  footer .links li {
    padding: 5px 5px;
    margin-top: 4px;
    text-align: center;
  }

  .deals table {
    font-size: 13px;
  }

  .deals table td {
    padding: 0px 10px;
  }

  .deals table th {
    padding: 0px 10px;
  }

  footer .links {
    display: block;
    column-count: 1;
  }

  .footerlogo {
    margin-bottom: 20px;
  }

  header .container {
    padding-left: 0px;
    padding-right: 0px;
  }

  .navbar-collapse {
    background: rgb(15, 102, 158);
  }

  .navigation-wrap {
    position: fixed;
    background: #fff;
  }

  .blueheader .navigation-wrap {
    position: fixed;
    background: #0f669e;
  }

  .banner__div {
    padding-top: 30px;
  }

  footer .copyright li {
    text-align: left;
  }

  .deals {
    background-size: 25%;
  }

  .cta {
    background-size: 18%;
  }

  .navbar-nav {
    display: -webkit-box !important;
  }

  .breadcrumbsec h1 {
    font-size: 19px;
    line-height: 1.5;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    .breadcrumbsec {
      background-repeat: no-repeat;
      padding: 0;
    }

    .breadcrumbsec h1 {
      padding: 10px 0;
      font-size: 15px;
      line-height: 10px;
    }

    .breadcrumbsec p {
      font-size: 14px;
      line-height: 14px !important;
    }
  }

  .breadcrumbsec p {
    font-size: 14px;
    line-height: 1.5;
  }

  .faq-heading {
    font-size: 12px;
    padding: 9px 10px;
    line-height: 1.5;
  }



  .faq input[type="checkbox"]:not(:checked)+label {
    height: 55px;
  }

  .faq .faq-arrow {
    top: -36px;
    right: 11px;
  }

  .faq {
    margin-top: 20px;
  }

  .faq-text {
    font-size: 12px;
  }

  .mem-title {
    margin-top: 10px;
  }
}

@media (min-width: 601px) and (max-width: 992px) {
  header .container {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .banner__caption h1 {
    font-size: 32px;
    line-height: 1.3;
    color: #fff;
  }

  .banner__caption_general h1 {
    font-size: 32px;
    line-height: 1.3;
    color: #fff;
  }

  .banner__caption_lender h1 {
    font-size: 32px;
    line-height: 1.3;
    color: #fff;
  }

  .logo img {
    width: 115px;
  }

  .works__card p {
    font-size: 13px;
  }

  .works__card {
    padding: 11px;
  }

  .deal__description .deals__primarytxt {
    font-size: 24px;
    line-height: 1.5;
    margin-bottom: 4px;
  }

  .deals table {
    font-size: 11px;
  }

  .nav-link {
    display: block;
    padding: 0.5rem 0.3rem !important;
  }
}

@media (min-width: 993px) and (max-width: 1024px) {
  .banner__caption h1 {
    font-size: 43px;
    line-height: 1.4;
  }

  .banner__caption_general h1 {
    font-size: 43px;
    line-height: 1.4;
  }

  .banner__caption_lender h1 {
    font-size: 43px;
    line-height: 1.4;
  }
}

.mobileGridFooter .mobileShow {
  display: none;
}

.mobileGridFooter .mobileHide {
  display: block;
}

.mobileBg {
  display: none;
}

@media (max-width: 600px) {
  .absoluteheader .navbar-nav {
    display: block !important;
  }

  .loginbtn {
    margin-top: 10px;
  }

  .absoluteheader .navbar-nav .nav-link:hover {
    color: #fff;
  }

  .howItWorks,
  .howItWorksImage_left,
  .howItWorksImage_right {
    width: 100%;
  }

  .work_steps_lender {
    padding: 20px 0;
    text-align: center;
  }


  .mobileTitle {
    padding-top: 20px;
    margin-bottom: 10px;
  }

  .work_steps br {
    display: none;
  }

  /* .blueheader .navbar-nav {
    display:block !important;
} */
  .blueheader .navbar-nav .nav-link:hover {
    color: #fff;
  }

  .blueheader .loginbtn a {
    margin: 0;
  }

  .footerlogo {
    width: 50%;
  }

  footer .copyright {
    display: block;
  }

  footer .copyright li {
    text-align: center;
  }

  .mobileGridFooter .mobileShow {
    display: block;
  }

  .mobileGridFooter .mobileHide {
    display: none;
  }

  .mobileGridFooter br {
    display: none;
  }

  .webBg {
    display: none;
    font-family: MuseoSans bold;
  }

  .mobileBg {
    display: block;
    width: 100%;
    font-family: MuseoSans bold;
  }

  .fundevertxt__button {
    font-size: 13px;
  }

  .cta__button--success {
    font-size: 16px;
    padding: 8px 20px;
  }

  .mobileMargin {
    margin: 0px;
  }

  .banner__caption {
    width: 80%;
    margin-bottom: 45%;
  }

  .banner__caption_general {
    width: 80%;
    margin-top: -8%;
    margin-left: -3%;
  }

  .banner__caption_lender {
    width: 80%;
  }

  .banner__caption h1 {
    font-size: 20px;
    line-height: 1.2;
    margin-bottom: 15%;
  }

  .banner__caption_general h1 {
    font-size: 26px;
    line-height: 1.2;
    margin-bottom: 0px;
  }

  .banner__caption_lender h1 {
    font-size: 20px;
    line-height: 1.2;
    margin-bottom: 0px;
  }

  .blueheader .loginbtn a {
    text-align: left;
  }

  .blueheader .loginbtn {
    margin-top: 0px;
  }

  .blueheader .navbar-collapse .navbar-nav {
    display: block !important;
  }

  .sidebar-dark {
    display: block !important;
  }

  .sidebar-dark .nav-item .nav-link {
    padding: 1rem 1rem !important;
  }

  .pagetitle-header {
    margin-top: 26px;
  }

  .loginuser {
    padding: 10px 0px;
  }

  .solidbtn {
    font-size: 14px;
    padding: 0.45rem 1rem;
  }

  .rejectbtn2 {
    font-size: 14px;
    padding: 0.45rem 1rem;
  }

  .pagetitle h1 {
    font-size: 20px;
  }
}

@media (min-width: 567px) {
  .mediumwidth-modal .modal-dialog {
    max-width: 58%;
    margin: 1.75rem auto;
  }
}

.collasible-table .dropdown-toggle::after {
  display: none;
}

/* .collasible-table .dropdown-toggle{
font-size: 21px;
} */

.collasible-table td:last-child {
  border-radius: 8px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.collasible-table td:first-child {
  border-radius: 3px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.collasible-table .view-all td {
  width: 30%;
  padding: 10px 0;
}

.collasible-table .view-all td .file-folder {
  padding-right: 10px;
}

.borrowerProfileDocumentCard {
  width: 850px;
  margin: auto;
}

.borrower-doc-icon {
  padding-right: 20px;
}

.borrower-download-button {
  padding-left: 180px;
}

.borrower-delete-button {
  padding-left: 30px;
}

.collasible-table .view-all td:last-child {
  text-align: left;
}

.shareddocument {
  padding: 10px;
}

.fullwidthtab .collasible-table.shareddocument th {
  padding-left: 0px;
}

.countDoc {
  font-weight: 600;
}

.shareddocument select {
  background: transparent;
  border: none;
  color: rgba(0, 0, 0, 0.8);
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
}

.shareddocument .form-group {
  margin-bottom: 0px;
}

.shareddocument .row-tr {
  padding: 10px;
  min-height: 57px;
  border-radius: 6px !important;
  background: #ffffff;
  box-shadow: 0px 1px 12px rgba(102, 102, 102, 0.1);
  color: #44566c;
  text-transform: capitalize;
  /* font-family: HelveticaNeue medium; */
}

.shareddocument .row-tr.checked {
  background: #98a9bc;
  color: #fff;
  font-weight: 400;
  border-radius: 6px;
  border: 10px solid #98a9bc;
  box-shadow: 0px 1px 12px rgba(102, 102, 102, 0.1);
}

.shareddocument .dropdown {
  /* margin-right: 10px !important; */
}

.shareddocument .colorwhite {
  color: #fff !important;
}

.flatpickr-day.selected {
  background: #0f669e !important;
}

.flatpickr-day:hover {
  background: #0f669e;
  color: #fff;
}

.bluepanel {
  background: #0f669e;
  color: #fff;
  padding: 20px;
  font-family: HelveticaNeue medium;
}

.perdiv {
  position: absolute;
  right: 20px;
  top: 44px;
  color: #444;
}

.perdivSummary {
  position: absolute;
  right: 17px;
  top: 12px;
  color: #444;
}

.border-rad-5 {
  border-radius: 5px;
}

.add-new {
  border: 1px solid #0f669e;
  background: #fff;
  color: #0f669e;
  padding: 8px 20px;
  font-size: 15px;
  border-radius: 5px;
  font-family: HelveticaNeue medium;
}

.add-new.right {
  float: right;
}

.add-new-form {
  margin-bottom: 30px;
}

/*Drop Down*/

.dropdown-check-list {
  display: inline-block;
}

.dropdown-check-list .anchor {
  position: relative;
  cursor: pointer;
  display: inline-block;
  padding: 5px 50px 5px 10px;
  border: 1px solid #ccc;
}

.dropdown-check-list .anchor:after {
  position: absolute;
  content: url(../img/dropdown.png);
  /* border-left: 2px solid black;
  border-top: 2px solid black; */
  padding: 5px;
  right: 10px;
  top: 20%;
  font-family: "FontAwesome";
  /* -moz-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg); */
}

.dropdown-check-list .anchor:active:after {
  right: 8px;
  top: 21%;
}

.dropdown-check-list ul.items {
  padding: 2px;
  display: none;
  margin: 0;
  border: 1px solid #ccc;
  border-top: none;
}

.dropdown-check-list ul.items li {
  list-style: none;
}

.dropdown-check-list {
  background: rgba(9, 19, 33, 0.0638304);
  border: none;
  font-size: 13px;
  width: 100%;
}

.dropdown-check-list .anchor {
  background: none;
  width: 100%;
}

.dropdown-check-list .anchor {
  width: 100%;
  border: none;
  width: 100%;
  border: none;
  height: 50px;
  padding: 15px;
}

.dropdown-check-list ul.items {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding-left: 30px;
}

.dropdown-check-list ul.items .custom-control {
  padding: 10px;
  font-weight: 100;
  font-family: HelveticaNeue;
}

.dopdown-check-list form.user .custom-checkbox.small label {
  font-family: HelveticaNeue;
}

/*End Drop Drow*/

@media (min-width: 1600px) {
  .minheight617 {
    min-height: 92vh;
    max-height: 92vh;
    overflow-x: auto;
  }
}

@media (max-width: 600px) {
  .sidebar {
    width: 6.5rem !important;
  }

  .loginuser h4 {
    font-size: 11px;
  }

  .card-description {
    padding: 1rem 0.5px;
    min-height: 202px;
  }

  .primarybtn {
    font-size: 12px;
    border: none;
    padding: 3px 10px;
  }

  .pagetitle-header .col-7 {
    flex: 76%;
    max-width: 76%;
    align-self: center;
  }

  .pagetitle-header .mt-3 {
    margin-top: 0px !important;
  }

  .nav-tabs .nav-link {
    padding: 0.5rem 0.5rem !important;
    font-size: 12px;
  }

  .opp-detail .detailicon {
    right: -2px;
  }

  .summary-div .editbtn {
    top: 22px;
    font-size: 13px;
  }

  .summary-div .detailbtn {
    top: 22px;
    font-size: 13px;
  }

  .gray-title {
    font-size: 15px;
    margin-top: 10px;
  }

  .document-list h4 {
    font-size: 14px;
  }

  .rightbtn {
    top: 22px;
    right: -9px;
  }

  .collasible-table .table-row td {
    font-size: 15px;
    padding: 10px;
  }

  .card-14 {
    font-size: 12px;
  }

  .opp-detail .totalviews h4 {
    font-size: 18px;
  }

  .dangerbtn {
    margin-right: 0px !important;
  }

  .pad-all-20 {
    padding: 10px;
  }

  .pagetitle-header .col-6 {
    flex: 0 0 75%;
    max-width: 60%;
  }

  .modal-dialog .borderbtn {
    margin-right: 0px !important;
    margin-bottom: 10px !important;
  }

  .modal-dialog .primarybtn {
    font-size: 12px;
    border: none;
    padding: 10px 10px;
  }

  .profile-images {
    display: none;
  }

  .deal__description .float-right {
    float: unset !important;
  }

  .primarytags {
    display: -webkit-flex;
    position: relative;
    top: -11px;
    left: 0px;
  }

  .deal__description p {
    margin-top: 10px;
  }

  .stepper ul li .title {
    font-size: 1;
  }

  .pagetitle h1 {
    font-size: 17px;
    line-height: 1.4;
  }

  .bg-white .col {
    align-self: center;
  }

  .borderbtn {
    text-align: center;
    font-size: 12px;
    padding: 7px;
  }

  .bg-white .container {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .stepper ul li .title {
    font-size: 12px;
    line-height: 1.3;
  }

  .stepper ul li {
    padding: 4px;
  }

  .stepper ul li .description {
    font-size: 10px;
    line-height: 1.4;
  }

  .stepper ul li .number {
    width: 24px;
    height: 24px;
    padding: 3px;
  }

  p {
    font-size: 13px;
  }

  .lender {
    font-size: 10px;
    padding: 2px 5px;
    border-radius: 3px;
    width: 72px;
  }

  .redtag {
    margin-left: 0px;
  }
}

.darkgraybg {
  background: #bfbfbf !important;
}

.stepper ul li a:hover {
  text-decoration: none;
}

.stepper ul li:hover {
  text-decoration: none;
  background: rgba(15, 102, 158, 0.18);
}

.reactdatepicker .form-control {
  width: 100%;
  padding: 13px;
  color: black;
  height: 47px;
  border: none;
  font-size: 13px;
  background: rgba(9, 19, 33, 0.0638304);
}

.reactdatepicker .react-datepicker-wrapper {
  position: relative;
}

.reactdatepicker .react-datepicker-wrapper:after {
  content: url(../img/datepicker.png);
  position: absolute;
  right: 16px;
  top: 11px;
}

.darkgraybg {
  background: #bfbfbf !important;
}

.preview-sec .previewList {
  background: #f5f5f5;
  margin-bottom: 25px;
  font-size: 13px;
  text-align: center;
}

.preview-sec .previewList .uploader {
  min-height: 100px;
  padding: 20px 5px;
}

.thumbInner img {
  display: "block";
  width: 100%;
  height: auto;
}

.remove-icon {
  color: #f63b3b;
  position: absolute;
  top: 0;
  right: 5px;
  font-size: 22px;
}

.transparent-bg .nav-tabs .nav-link.active {
  color: #0f669e !important;
  border: none;
  border-bottom: 2px solid #0065f2;
  background-color: transparent !important;
  background: transparent !important;
}

.previewList {
  position: relative;
}

.previewList .btn-danger {
  color: #fff !important;
  font-weight: 900;
  padding: 5px;
  font-size: 13px;
  height: 30px;
  width: 30px;
  border-radius: 100px;
  position: absolute;
  top: 50%;
  left: 49%;
}

.previewList h5 {
  font-size: 0.9rem;
}

.alldeletebg .borderbtn {
  border-radius: 5px;
  margin-right: 15px;
}

.alldeletebg .primarybtn {
  border-radius: 5px;
}

/* weni */

.popover-tooltip {
  background: #fff;
  border: 1px solid #fff;
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #000000;
}

.popover.bs-popover-bottom {
  max-width: initial;
  min-height: 138px;
  transform: translate(264px, 214px) !important;
}

#popover-contained.popover .arrow {
  transform: translate(170px, 0px) !important;
}

#popover-contained-property.popover .arrow {
  transform: translate(50px, 0px) !important;
}

#popover-amount.popover .arrow {
  transform: translate(40px, 0px) !important;
}

#popover-amount.popover.bs-popover-bottom {
  transform: translate(545px, 215px) !important;
  max-width: 276px;
}

#popover-region.popover .arrow {
  transform: translate(60px, 0px) !important;
}

#popover-region.popover.bs-popover-bottom {
  transform: translate(838px, 215px) !important;
  width: 414px;
  max-height: 590px;
}

#popover-region .multi-select {
  margin-right: 2px;
}

#popover-closing-date.popover .arrow {
  transform: translate(40px, 0px) !important;
}

#popover-closing-date.popover.bs-popover-bottom {
  transform: translate(645px, 215px) !important;
  max-width: 276px;
}

@media screen and (min-width: 1290px) {
  #popover-amount.popover.bs-popover-bottom {
    transform: translate(655px, 215px) !important;
  }

  #popover-contained.popover .arrow {
    transform: translate(230px, 0px) !important;
  }

  #popover-region.popover.bs-popover-bottom {
    transform: translate(260%, 215px) !important;
  }
}

.reactdatepicker.opportunity:after {
  display: none;
}

.rdt.reactdatepicker.opportunity {
  position: absolute;
  top: 38px;
  z-index: 1;
}

.reactdatepicker.opportunity.rdtStatic .rdtPicker {
  width: 349px;
}

header {
  width: 100%;
  top: 0px;
  position: fixed;
  z-index: 300;
  transition: all 0.5s;
}

.header-scrolled {
  position: fixed !important;
  top: 0px;
  left: 0px;
  transition: all 0.5s;
  right: 0px;
  width: 100%;
  background: #0f669e;
}

.header-scrolled-home {
  position: fixed !important;
  top: 0px;
  left: 0px;
  transition: all 0.2s;
  right: 0px;
  width: 100%;
  padding: 0px 0px;
  background: #0f669e;
}

.hide-logo {
  display: none;
}

.dekstop-top-65 {
  padding-top: 65px;
}

.banner__div {
  margin-top: -65px;
}

@media (max-width: 600px) {
  .dekstop-top-70 {
    margin-top: 0px;
  }

  .header-scrolled {
    position: relative !important;
    top: 0px;
    left: 0px;
    transition: all 0.5s;
    right: 0px;
    width: 100%;
    height: auto;
  }

  header {
    position: relative;
  }
}

.mtp-5 {
  margin-top: 5rem !important;
}

.deals table td {
  width: 0%;
  height: 56px;
}

.rounedtxt {
  display: block;
  min-width: 100px;
  text-align: center;
  line-height: 15px;
  text-transform: capitalize;
}

.rounedtxt.dropdown-toggle {
  background: #fff;
}

.rounedtxt.dropdown-toggle::after {
  margin-top: 6px;
  float: right;
  margin-left: 0;
}

.idtagMarginTop {
  margin-top: 10px;
  width: 165px;
}

.pendingExtraPadding {
  padding: 8px 36px;
}

.requestExtraPadding {
  padding: 8px 66px;
}

.idtag {
  text-transform: uppercase;
}

.idtag.block {
  position: absolute;
  right: 30px;
  top: 15px;
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: right;
  color: rgba(0, 0, 0, 0.8);
  opacity: 0.6;
}

.pagetitle-header .searchbargray .form-group {
  margin-bottom: 0px !important;
  min-width: 390px;
}

.scrolling-section {
  max-height: 98vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.tab-pane .deal__description .deals__primarytxt {
  min-height: 20px;
}

.blackcolor {
  color: black;
}

.action {
  padding-left: 48px !important;
}

.width30 {
  width: 30%;
}

.width60 {
  width: 60%;
}

.width10 {
  width: 10%;
}

.steppertitle {
  margin: 0 !important;
}

.steppertitle h1 {
  color: #4f5a68;
  font-weight: normal;
  text-transform: none;
  margin: 5px 0;
}

.steppertitle .iconcolor {
  color: #4f5a68;
}

.stepper ul .nav-link .number {
  width: 30px;
  height: 30px;
  padding: 5px 4px;
  font-size: 12px;
  margin: 10px auto;
  background: #fff;
  color: #0f669e;
  border: 1px solid #0f669e;
  font-family: HelveticaNeue medium;
  border-radius: 100px;
}

.stepper ul .active .number {
  background: #0f669e;
  color: #fff;
}

.custom-control-label::before {
  top: 0.15rem;
  left: -1.7rem;
}

.profileSection .custom-control-label::after {
  top: 0.28rem !important;
  left: -1.75rem;
}

.custom-control-label::after {
  top: 0.3rem;
  left: -1.68rem;
}

.dividerline2px {
  border-bottom: 2px solid;
  margin: 10px 0;
  width: 280px;
  padding: 5px 0;
}

.fontbig {
  font-size: 25px;
}

.pad-5 {
  padding: 5px 0;
}

.formuladiv {
  font-size: 20px;
  width: 89%;
}

.equaltoicon {
  width: 150px;
  align-self: center;
}

.col-mar-38 {
  margin-top: 38px;
}

/* weni */

.card-header .profile-heading {
  /* font-family: Helvetica Neue; */
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  /* or 133% */
  display: flex;
  align-items: center;
  letter-spacing: 0.7px;
  /* Primary 1 – #0065F2 */
  color: #0f669e;
}

.pd-50 {
  padding: 50px;
}

.blog {
  line-height: 32px;
  color: #000;
  font-style: normal;
  font-weight: normal;
}

.back-bolg h4 .link-blog {
  font-weight: 800;
  font-size: 24px;
  color: #000;
}

.blog-title {
  color: #000;
  font-weight: 700;
  line-height: 35px;
  font-size: 30px;
}

.bolg-content {
  line-height: 1.7em;
}

.blog-posted {
  font-style: italic;
  font-size: 14px;
  color: #000;
  padding: 5px 0;
  margin: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.publish-now {
  padding-bottom: 20px;
}

.publish-now .centertxtbold {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 32px;
  color: #8697a8;
  padding: 16px 0 0 0;
}

.accessRevoke {
  background: #d12d17;
  border: 2px solid #d12d17;
  color: #fff;
  font-size: 15px;
  border-radius: 5px;
  padding: 8px 38px;
  font-family: HelveticaNeue medium;
}

.accessRevoke:disabled {
  background: #d12d17;
  border: 2px solid #d12d17;
  color: #fff;
  font-size: 15px;
  border-radius: 5px;
  padding: 8px 38px;
  font-family: HelveticaNeue medium;
  line-height: normal;
}

.blueCircle {
  width: 39px;
  height: 39px;
  border-radius: 100px;
  margin-right: 10px;
  display: inline-block;
  background: #09ddf4;
  text-align: center;
  color: #fff;
  padding: 8px 0 0 0;
  font-weight: bold;
}

.redCircle {
  width: 39px;
  height: 39px;
  border-radius: 100px;
  margin-right: 10px;
  background: #fa525e;
  text-align: center;
  color: #fff;
  padding: 8px 0 0 0;
  display: inline-block;
  font-weight: bold;
}

.closedDeal {
  text-align: center;
  background: #d12d17;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  padding: 32px;
}

.closedDealFunded {
  text-align: center;
  background: #0f669e;
  color: #fff;
  font-size: 18px;
  padding: 32px;
}

.closedDealReopened {
  background: #ffb800;
  font-family: Helvetica Neue;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
  color: #ffffff;
}

.liveDeal {
  /* background: #2085ff; */
  background: #007ba7;
  font-family: Helvetica Neue;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
  color: #ffffff;
}

.closedGapDeal {
  /* background: #2085ff; */
  background: gray;
  font-family: Helvetica Neue;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
  color: #ffffff;
}

.closedDealReopened .span {
  font-weight: bold;
}

.closedDealFunded span {
  font-weight: 700;
  font-size: 20px;
}

.empty-message {
  text-align: center;
  font-size: 22px;
  text-transform: uppercase;
  height: 50vh;
}

.filesFlex {
  display: flex;
  flex-direction: column;
}

.drop-box-height {
  height: 190px;
}

.outer-container {
  margin: 10px auto;
}

.drop-box-outer {
  position: relative;
  height: 72px;
  background-color: #f8fafb;
  overflow: hidden;
}

.drop-box-outer .drop-box-close {
  display: none;
}

.drop-box-outer:hover .drop-box-close {
  display: block;
}

.drop-box-outer-container {
  display: inline-block;
}

.drop-box-img {
  padding: 16px;
}

.drop-box-uploading {
  /* position: absolute;  */
  left: 62px;
  top: calc(50% - 16px / 2 - 10px);
  font-size: 13px;
  line-height: 15px;
  align-items: center;
  text-transform: capitalize;
}

.drop-box-close {
  position: absolute;
  width: 16px;
  height: 16px;
  right: 7px;
  top: 7px;
  cursor: pointer;
}

.drop-box-uploading-line {
  position: absolute;
  height: 5px;
  width: 65%;
  left: 63px;
  right: 16px;
  top: calc(50% - 5px / 2 + 12.5px);
  border-radius: 5px;
}

.drop-box-input {
  width: 352px;
}

.table-heading-title {
  color: #44566c;
  text-transform: uppercase;
}

.table-heading-title.heading-padding {
  padding: 32px 0 14px 0;
}

.table-heading-title.heading-padding div {
  padding-left: 32px;
}

/* my opp */

/* .my-opp-shadow {
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
} */

.tabs-bg .nav-tabs.lender-opportunity .nav-item {
  min-width: 25%;
}

.shareddocument .font-15 .heading-table {
  color: #44566c;
}

.shareddocument .heading-table {
  font-size: 12px;
  color: #44566c;
}

.heading-table2 {
  /* font-family: Helvetica Neue; */
  color: #44566c;
  font-size: 12px;
}

.deals table .row-table-color td {
  color: #44566c;
  font-size: 15px;
  font-weight: normal;
}

.deals table .row-table-color td .dropdown-item {
  color: #8697a8;
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
}

.tabs-bg .nav-tabs.lenders-tab-panel .nav-item {
  min-width: 193px;
}

.tabs-bg .nav-tabs.view-all-tab-panel .nav-item {
  min-width: 174px;
}

.white-bg-card {
  height: 829px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(118, 118, 118, 0.25);
  border-radius: 2px;
}

.collasible-table .table-row.row-table-document {
  background: #f4f4f4;
  border-radius: 3px;
  box-shadow: none;
}

.tabs-bg .nav-tabs.message-tabs .nav-item {
  min-width: 33.3%;
}

.collasible-table.lenderDocument .row-table-document td:first-child {
  width: 25%;
}

.collasible-table.lenderDocument .heading-table-doc {
  padding: 0 0 0 38px;
  color: #000;
}

/* lenders profile css */

.lendingUser {
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.lender-profile-creation .card {
  border-radius: 0;
}

.tabs-bg .nav-tabs.lender-profile .nav-item {
  min-width: 50%;
}

.tabs-bg .nav-tabs.lender-profile .nav-link.active {
  color: #fff !important;
  border: none;
  background: #0f669e;
  border-radius: 0;
  box-shadow: #e6e6e6;
}

.form-heading {
  background: #0f669e;
}

.form-heading p {
  font-weight: bold;
  font-size: 14px;
  line-height: 10px;
  color: #ffffff;
  letter-spacing: 0.2px;
  padding-left: 30px !important;
}

.shevron-icon {
  position: absolute;
  top: 0px;
  left: 13%;
}

.add-new.btn-block {
  border-radius: 0;
}

.selectdropdown2 {
  background: #ffffff;
  border: 1px solid #8b96a3;
  border-radius: 3px;
}

.filter_outer {
  background: #ffffff;
  border: 1px solid #0f669e;
  box-sizing: border-box;
  border-radius: 2px;
  color: #0f669e;
  padding: 6px;
  min-width: 125px;
  font-size: 15px;
  font-weight: 500;
}

.for-layout .layot {
  display: inline-block;
}

.for-layout .layot:first-child {
  padding: 0;
}

.for-layout .layot:nth-child(5) {
  padding: 0;
}

.filter_outer .closing {
  position: absolute;
  right: 7px;
  top: 0;
}

.for-layout .layot .fa {
  font-size: 12px;
  font-weight: 400;
}

.lendingUser .custom-control-label::before {
  border-radius: 1px !important;
}

.lendingUser .custom-control-label::after {
  top: 5px;
}

.lendingUser hr {
  border-color: #c4c4c4;
}

/* .thumnailimg{
    position: absolute;
    top: 100px;
    float: right;
    left: 89%;
    right: 0;
}
.thumnailimg:nth-child(2){
    top: 0;
}
.thumnailimg:nth-child(1){
    position: relative;
    bottom: 0;
    top: 0;
    float: none;
    left: 0;
    right: 0;
}
.thumnailimg img{
  width: 70px;
}
.thumnailimg:nth-child(1) img{
    width: 100%
}
.locationimg{
 position: relative;
} */

.thumnailimg {
  text-align: right;
  width: 25%;
  position: absolute;
  right: 7px;
  top: 6px;
}

.thumnailimg img {
  width: 70px;
  margin-bottom: 8px;
}

.thumnailimg:first-child {
  display: none;
}

.locationimg {
  position: relative;
}

.chat-conversation .chat-person-left {
  min-width: 65%;
}

@media (min-width: 1200px) {
  .chat-conversation .chat-person-right .message {
    margin-right: 30px;
  }
}

/*newcss*/

.inlineul {
  padding-left: 21px;
  display: -webkit-inline-box;
  font-size: 14px;
  padding-left: 15px;
  margin-bottom: 6px;
  color: rgba(0, 0, 0, 0.8);
}

.inlineul li {
  display: flex;
  padding: 0 8px 0 0;
}

.block-table thead th {
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.8);
  opacity: 0.6;
}

.block-table tbody {
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.8);
}

.requstbtn {
  padding: 8px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  font-family: Helvetica Neue;
  align-items: center;
  text-align: center;
  border: 1px solid rgba(15, 102, 158, 0.58);
  box-sizing: border-box;
  border-radius: 2px;
  color: #0f669e;
  background-color: white;
}

.requstbtn:hover {
  text-decoration: none;
}

.dropdownlist .navbar {
  background: #ffffff;
  border: 1px solid rgba(15, 102, 158, 0.2);
  box-shadow: 0px 4px 4px rgba(204, 204, 204, 0.25);
  border-radius: 33px;
  margin: 10px 0;
  padding: 0 10px;
}

.dropdownlist .navbar a {
  float: left;
  font-size: 16px;
  color: #000;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.dropdownlist .dropdown {
  float: left;
  /* overflows: hidden; */
}

.dropdown .dropbtn {
  border: none;
  outline: none;
  font-size: 15px;
  line-height: 18px;
  /* display: flex; */
  align-items: center;
  text-align: center;
  color: #0f669e;
  padding: 14px 7px 14px 5px;
  background-color: inherit;
  font-family: inherit;
  border-right: 1px solid rgba(15, 102, 158, 0.2);
}

.dropdown:last-child .dropbtn {
  border-right: 0;
}

.dropdownlist .dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 500px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 10px;
}

.dropdownlist .popover-body {
  padding: 0;
}

.dropdownlist .dropdown-content a,
.popover-body .dropdown-content a,
.dropdownlist .dropdown-content .span-elm {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover,
.dropdown-content .span-elm:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown .dropbtn .fa {
  margin-left: 5px;
}

.dropdown .dropbtn .shevron-icon {
  top: 14px;
  left: 700%;
}

/* today's */

.link-text {
  color: #0f669e;
  /* font-family: Helvetica Neue; */
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}

.rdtPicker {
  left: 49%;
  right: 0;
  margin-top: -7px;
  padding: 0;
  border-radius: 5px;
  z-index: 10 !important;
}

.rdtPicker .rdtDays table thead tr:first-child {
  background: #c4c4c4;
}

.rdtPicker .rdtDays table thead tr:first-child th {
  padding: 22px 0;
  color: #44566c;
  text-transform: capitalize;
}

.rdtPicker .rdtDays table thead tr th {
  padding: 14px 0 22px 0;
  color: #8697a8;
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  text-transform: uppercase;
}

.rdtPrev span,
.rdtNext span {
  font-size: 30px;
}

.listview {
  padding-left: 0px;
  display: -webkit-inline-box;
  margin-bottom: 0px;
}

.listview li {
  list-style-type: none;
  cursor: pointer;
}

.bluebglist {
  background: #0f669e;
  box-shadow: 0px 4px 4px rgba(158, 158, 158, 0.25);
  border-radius: 3px;
  padding: 5px 18px;
  color: #ffffff;
}

.bluebglist a {
  color: #ffffff;
}

.whitebglist {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(158, 158, 158, 0.25);
  border-radius: 3px;
  padding: 7px 18px;
  color: #0f669e;
}

.whitebglist a {
  color: #0f669e;
}

.listwidth {
  width: 39px;
}

.react-multiple-carousel__arrow {
  background: #0f669e;
}

.react-multiple-carousel__arrow::before {
  font-weight: bold;
}

.selectbox {
  background: #ffffff;
  border-radius: 1000px;
  padding: 9px 20px;
  font-size: 15px;
  color: #44566c;
  border: 1px solid rgba(15, 102, 158, 0.2);
  box-shadow: 0px 4px 4px rgba(204, 204, 204, 0.25);
  width: 100%;
  height: 46px;
  margin: 10px 0;
}

.selectbox .optionBox {
  font-size: 14px;
  line-height: 17px;
  margin-top: 30px;
  height: 50px;
  color: #28b440;
  background-color: #000;
}

.checkbox-left {
  margin-left: 15px;
}

.black {
  color: #000000;
}

.floating {
  float: right;
}

.user .form-label {
  color: rgba(0, 0, 0, 0.8);
}

.viewAll-bg {
  background: #ffffff;
  border-radius: 4px;
}

.viewAll-bg tbody {
  /* font-family: Helvetica Neue; */
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #252631;
}

.viewAll-bg thead tr {
  /* font-family: Helvetica Neue; */
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #98a9bc;
}

.view-all .dropdown {
  display: inline-block;
  padding: 0 0 0 10px;
}

form .form-control::-webkit-input-placeholder {
  /* font-family: Helvetica Neue; */
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #909091;
  letter-spacing: 0.1px;
}

form .form-control::-moz-placeholder {
  /* font-family: Helvetica Neue; */
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #909091;
  letter-spacing: 0.1px;
}

form .form-control:-ms-input-placeholder {
  /* font-family: Helvetica Neue; */
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #909091;
  letter-spacing: 0.1px;
}

form .form-control:placeholder {
  /* font-family: Helvetica Neue; */
  font-weight: normal;
  color: #909091;
  letter-spacing: 0.1px;
}

.br-3 {
  border-radius: 3px;
}

.three-dot {
  opacity: 0.6;
}

.actions-dropdown .dropdown {
  display: inline-block;
  padding-left: 65px;
}

.notify {
  /* height: 635px; */
  height: 47vh;
  overflow-y: overlay;
  overflow-x: hidden;
}

.opportunity-margin {
  margin-bottom: 20px;
}

.network .collasible-table th {
  padding-left: 28px;
}

.network .collasible-table tr td {
  width: 40%;
}

.network .collasible-table tr td:last-child {
  text-align: left;
}

.slidingCaptchaHeader {
  background: #f5f5f5;
  display: block;
}

.slidingCaptchaHeader h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1px;
  color: #000000;
}

.slidingCaptchaHeader h6 {
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 8px;
  /* identical to box height, or 171% */
  display: flex;
  align-items: center;
  letter-spacing: 0.1px;
  color: #000000;
}

#sliderCapcha .slideCaptchaContainer .panel {
  display: block !important;
}

.popoverValidation {
  background-color: #d12d17;
  padding: 9px 9px;
  color: white;
  border-radius: 0;
  margin-left: 30px;
}

.popoverValidation:before {
  content: "";
  position: absolute;
  top: 12px;
  left: -20px;
  z-index: 1;
  border: solid 10px transparent;
  border-right-color: #d12d17;
}

.errorBorder {
  border: 1px solid #d12d17 !important;
}

.lender-task {
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.1px;
  color: rgba(0, 0, 0, 0.8);
}

.btn-style {
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.lender-task-table .collasible-table.lenderDocument .row-table-document td:first-child {
  width: 15%;
}

.lender-task-table .collasible-table.lenderDocument .row-table-document td:last-child {
  width: 26%;
}

.lender-task-table .desc {
  width: 26%;
}

.lender-task-table .collasible-table.lenderDocument .row-table-document td.upload-file {
  padding-left: 23px;
}

.ImageThumbnail {
  width: 100% !important;
  height: 372px !important;
  object-fit: cover;
}

.top-mr {
  margin-top: 24px;
}

.sharedDocFont {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */
  text-transform: uppercase;
  /* Body/Text */
  color: #44566c;
}

.doc .dropdown-item {
  color: #8697a8;
}

/* UI fixes on message tab*/

.form-control:focus,
input:focus {
  /* font-family: Helvetica Neue; */
  border: none !important;
  background: rgba(9, 19, 33, 0.0638304);
  box-shadow: 0 0 0 0 rgba(78, 115, 223, 0.25);
}

.chat-conversation .chat-person-left .showtime {
  position: absolute;
  bottom: -25px;
  right: -11px;
  text-align: right;
  width: 100%;
}

.chat-conversation .chat-person-left .message {
  position: relative;
}

.attachment-icons {
  width: 100px;
  text-align: center;
}

.publish-now .centertxtbold {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 32px;
  color: #8697a8;
  padding: 16px 0 0 0;
}

.accessRevoke {
  background: #d12d17;
  border: 2px solid #d12d17;
  color: #fff;
  font-size: 15px;
  border-radius: 5px;
  padding: 8px 38px;
  font-family: HelveticaNeue medium;
}

.accessRevoke:disabled {
  background: #d12d17;
  border: 2px solid #d12d17;
  color: #fff;
  font-size: 15px;
  border-radius: 5px;
  padding: 8px 38px;
  font-family: HelveticaNeue medium;
}

.blueCircle {
  width: 39px;
  height: 39px;
  border-radius: 100px;
  margin-right: 10px;
  display: inline-block;
  background: #09ddf4;
  text-align: center;
  color: #fff;
  padding: 8px 0 0 0;
  font-weight: bold;
}

.redCircle {
  width: 39px;
  height: 39px;
  border-radius: 100px;
  margin-right: 10px;
  background: #fa525e;
  text-align: center;
  color: #fff;
  padding: 8px 0 0 0;
  display: inline-block;
  font-weight: bold;
}

.closedDeal {
  text-align: center;
  background: #d12d17;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  padding: 32px;
}

.closedDealFunded {
  text-align: center;
  background: #0f669e;
  color: #fff;
  font-size: 18px;
  padding: 32px;
}

.closedDealReopened {
  background: #ffb800;
  border-radius: 4px;
  font-family: Helvetica Neue;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
  color: #ffffff;
  padding-top: 22px;
  height: 113px;
}

.closedDealReopened .span {
  font-weight: bold;
}

.closedDealFunded span {
  font-weight: 700;
  font-size: 20px;
}

.empty-message {
  text-align: center;
  font-size: 22px;
  text-transform: uppercase;
  height: 50vh;
}

.filesFlex {
  display: flex;
  flex-direction: column;
}

.drop-box-height {
  height: 190px;
}

.outer-container {
  margin: 10px auto;
}

.drop-box-outer {
  position: relative;
  height: 72px;
  background-color: #f8fafb;
  overflow: hidden;
}

.selectdropdown:disabled {
  background-color: #cccccc !important;
  color: black !important;
  /* background-image: url(""); */
}

.message-container {
  float: left;
}

@media (min-width: 768px) {
  .sidebar .nav-item .nav-link span {
    font-size: 0.85rem;
    display: inline;
    padding-left: 0.65rem;
  }

  .sidebar.minimized {
    width: 4.5rem !important;
  }
}

.chat-conversation .list {
  min-height: 62px;
}

.allFilter {
  /* padding-left: 180px; */
  float: right;
  padding-top: 10px;
}

.head-width {
  width: 33.33%;
}

.common-width {
  width: 33.33%;
}

.uncommon-width {
  width: 30%;
}

.uncommon-width2 {
  width: 10%;
}

.width-approve {
  width: 85px;
  line-height: normal;
}

.width-approve {
  padding: 8px 6px;
}

.addNewDocument {
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
  margin: 62px auto;
}

.addNewDocument span {
  font-size: 12px;
  font-weight: 800;
  padding-left: 10px;
}

.addNewCategory {
  text-align: center;
  border-radius: 4px;
  height: 130px;
  padding-top: 35px;
  margin-top: 35px;
  cursor: pointer;
  border: 1px dashed #989898;
}

.addNewCategory p {
  font-size: 12px;
  padding-top: 12px;
  font-weight: 700;
}

.buttonOverright {
  min-width: 166px;
  height: 51px;
  vertical-align: middle;
  text-align: center;
  padding-top: 12px;
}

.dangerbtn.buttonOverright {
  padding-top: 9px;
}

.filter-bar {
  padding-inline-start: 0;
  text-align: center;
  border: 1px solid rgba(15, 102, 158, 0.2);
  border-radius: 100px;
  width: 100%;
  margin-top: 11px;
}

.filter-bar .display-list .custom-control-label::before {
  border-radius: 0 !important;
}

.filter-bar .display-list {
  display: inline-block;
  padding: 10px 3px 10px 5px;
  background: #fff;
  border-right: 1px solid rgba(15, 102, 158, 0.2);
  width: 25%;
  text-align: center;
  vertical-align: middle;
  box-shadow: 0px 4px 4px rgba(204, 204, 204, 0.25);
}

.filter-bar .display-list.active {
  background: #0f669e;
  border-top: 3px solid rgba(15, 102, 158, 0.2);
  border-bottom: 3px solid rgba(15, 102, 158, 0.2);
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
}

.filter-bar .display-list.active .dropbtn {
  color: #fff;
}

.filter-bar .display-list:last-child {
  border-right: 0px;
  border-radius: 0px 100px 100px 0px;
}

.filter-bar .display-list:first-child {
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
}

.dropdownlist .filter-bar .display-list .dropdown .dropbtn {
  float: none;
  padding: 0;
  /* display: contents !important; */
}

.dropdownlist .filter-bar .display-list .dropdown {
  float: none;
}

.view span {
  left: 23px;
}

.filter-bar .react-datepicker__day--selected:focus {
  outline: none;
}

/* weni */

.select-search__select {
  max-height: 414px;
  overflow-y: auto;
}

.select-search__value input {
  height: 41px;
  background-image: url(../img/search.svg);
  background-position: 10px 10px;
  /* Center the image */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  width: 380px;
  margin-bottom: 28px;
  border-radius: 6px;
}

.select-search__options {
  list-style: none;
  padding-inline-start: 0;
}

.select-search__group {
  margin-bottom: 25px;
}

.select-search__group-header {
  font-family: Helvetica Neue;
  font-size: 14px;
  line-height: 17px;
  color: #778ca2;
  margin-bottom: 12px;
}

.select-search__row .select-search__option {
  border: 0;
  background: transparent;
  padding: 0;
  font-family: Helvetica Neue;
  font-size: 16px;
  line-height: 17px;
  color: #000000;
  padding: 10px;
}

.select-search--multiple .select-search__option {
  height: 48px;
}

.select-search__option.is-selected {
  background: #0f669e;
  color: #fff;
  width: 100%;
  text-align: left;
  padding: 10px;
  margin-bottom: 0;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
  background: #0a91e821;
  width: 100%;
  text-align: left;
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
  background: #128ede !important;
  color: #fff;
  font-family: Helvetica Neue;
}

.select-search--multiple .select-search__input {
  cursor: initial;
}

.select-search--multiple .select-search__input {
  border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
  cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:focus {
  border: 0;
}

.minimize-btn {
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  text-transform: capitalize;
  color: #0065f2;
  background: #d9ecff;
  border: 2px solid #d9ecff;
  padding: 18px 10px;
  margin: 0 12%;
  position: absolute;
  left: 0px;
  top: 700px;
}

.minimize-btn.collapsed {
  padding: 8px 10px;
  margin: 0 20%;
}

.table-responsive.taskList {
  overflow-x: hidden;
}

.stepper .nav::-webkit-scrollbar {
  display: none;
}

@media (min-width: 320px) and (max-width: 767px) {
  .table-responsive::-webkit-scrollbar {
    display: none;
  }
}

.taskList .collasible-table {
  display: block;
}

.taskList .collasible-table tbody {
  height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  display: block;
}

.card-height {
  height: 622px;
}

.displayText {
  padding-top: 25%;
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 20px;
  color: #000000;
}

.searchbargray.user-search {
  width: 239px;
  float: none;
  float: right;
}

.searchbargray.user-search input {
  background: #fff;
  border-radius: 6px;
}

.table-padding {
  margin-bottom: 10%;
}

.admin-table {
  width: 100%;
  background: #fff;
}

.admin-table .form-control {
  text-align: left;
}

.br-3 {
  border-radius: 3px;
}

.three-dot {
  opacity: 0.6;
}

.actions-dropdown .dropdown {
  display: inline-block;
  padding-left: 65px;
}

.notification-content {
  border-bottom: 1px solid #d0d0d0;
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: normal;
  padding: 1rem 2rem 1rem 1rem;
}

.notification-content:last-child {
  border: 0;
}

.notification-content h4 {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #000000;
}

.notification-content p {
  font-size: 13px;
  line-height: 20px;
  color: #8697a8;
  margin-block-end: 5px;
  display: inline;
}

.notification-content .sub-info {
  font-size: 12px;
  line-height: 15px;
  color: #98a9bc;
}

.notification-content .message-link {
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #0f669e;
}

.notification-content .message-title {
  display: inline-block;
  text-transform: capitalize;
}

.notification-content .message-id {
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  color: #707070;
  text-transform: uppercase;
}

.notification-content .primaryanchor {
  font-size: 13px;
}

.admin-table .table-head {
  background: #d1d1d1;
  border-radius: 5px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #98a9bc;
  text-transform: capitalize;
}

.admin-table .table-heading th,
.admin-table .table-row td {
  padding: 20px;
}

.pending-review-table .admin-table .table-row td {
  line-height: 25px;
  width: 33.33%;
}

.admin-table .table-heading th {
  min-width: 190px;
  overflow-x: auto;
}

.admin-table .table-row td {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 32px;
  letter-spacing: 0.3px;
  color: #252631;
}

.Failed.Verification {
  color: #eb5757;
  padding-right: 10px;
  padding-top: 2px;
}

.Verified {
  color: #219653;
  padding-right: 10px;
  padding-top: 2px;
}

.Pending.Verification {
  color: #ff9900;
  padding-right: 10px;
  padding-top: 2px;
}

.Invited {
  color: #2f80ed;
  padding-right: 10px;
  padding-top: 2px;
}

.Registered.User {
  color: #ff9900;
  padding-right: 10px;
  padding-top: 2px;
}

.custom-control.create {
  display: inline-block;
  float: right;
}

.create .custom-control-label::before {
  top: 0;
  left: -32px;
  border-radius: 0 !important;
}

.create .custom-control-label::after {
  top: 2px !important;
  left: -32px;
}

th.action-column {
  width: 33.33%;
  min-width: 275px;
}

.field-padding {
  padding: 0 6px;
  margin: 0;
}

.swal2-popup {
  border-radius: 0;
  margin-top: 25px;
}

.uppercase-text {
  text-transform: uppercase;
}

.color-black {
  color: black;
}

.position-relative {
  position: relative;
}

.count-top-right {
  top: -10px;
  right: -10px;
  position: absolute;
  left: auto !important;
}

.activeOpp {
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  text-transform: capitalize;
  color: #44566c;
}

.deals table .row-table-color .request {
  font-family: HelveticaNeue;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  text-transform: capitalize;
  color: #f2994a;
}

.heading-table2.uppercase {
  text-transform: capitalize;
  font-weight: 700;
  font-family: HelveticaNeue;
  font-style: normal;
  font-size: 15px;
  line-height: 18px;
  text-transform: capitalize;
}

.portfoliostatus {
  color: #13bd24;
}

.completedstatus {
  color: #0F669E;
}

.cancelledstatus {
  color: #eb5757;
}

.deals table .row-table-color .denied,
.deals table .row-table-color .revoked {
  font-family: HelveticaNeue;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  text-transform: capitalize;
  color: #eb5757;
}

.fullwidthtab .collasible-table.equalWidth th {
  width: 35%;
}

.fullwidthtab .collasible-table.equalWidth th:last-child {
  width: 30%;
}

@media (min-width: 992px) and (max-width: 1200px) {}

.main-checkbox {
  margin-bottom: 16px;
}

.taskHeader {
  margin-top: 50px;
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: #44566c;
  margin: 50px 20px 12px;
}

.taskStyle .table-row {
  margin: 0 20px 8px 20px;
  padding: 22px 15px;
  background: #ffffff;
  box-shadow: 0px 2px 14px rgba(102, 102, 102, 0.21);
  border-radius: 3px !important;
  color: #44566c;
}

.taskStyle .dropdown-toggle::after {
  display: none;
}

.taskStyle .scrolltaskList {
  height: 72vh;
  overflow-y: auto;
}

.taskStyle .task-font {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 18px;
  text-transform: capitalize;
  color: #44566c;
  align-self: center;
}

.taskStyle .desc {
  font-family: HelveticaNeue medium;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  text-transform: capitalize;
  color: #8697a8;
  align-self: center;
}

.taskStyle .task-font.top {
  position: relative;
  top: 7px;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick" !important;
  font-size: 35px !important;
  line-height: 1 !important;
  opacity: 0.75 !important;
  color: #0f669e !important;
  -webkit-font-smoothing: antialiased !important;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  color: #565656 !important;
}

.slick-prev,
.slick-next {
  width: 32px;
  height: 35px;
}

.closingBlock,
.text-reason {
  font-family: HelveticaNeue;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #ffffff;
  font-weight: 700;
}

.text-reason {
  font-weight: normal;
}

/* .closingBlock {
	font-weight: bold;
} */

.drop-box-outer.task {
  min-width: 215px;
  text-align: left;
  margin-bottom: 8px;
}

.drop-box-outer.task .drop-box-img {
  padding: 16px 7px;
}

.taskName {
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: rgba(0, 0, 0, 0.8);
  text-transform: capitalize;
  margin: 27px 0 15px 0;
}

.drop-box-outer.task a {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  text-decoration-line: underline;
  text-transform: capitalize;
  color: #44566c;
}

.viewtaskStyle p {
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: rgba(0, 0, 0, 0.8);
}

.viewtaskStyle label {
  font-family: HelveticaNeue;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 8px;
}

.viewtaskStyle .width-50 {
  border-radius: 4px;
  padding: 12px 20px;
}

.viewtaskStyle .primarybtn {
  font-family: HelveticaNeue medium;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}

.viewtaskStyle .borderbtn {
  font-family: HelveticaNeue medium;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #0f669e;
  border: 1px solid #0f669e;
  margin-top: 15px;
  margin-bottom: 50px;
}

#createTask .modal-content {
  width: 100%;
}

#deleteTask .modal-content {
  max-width: 540px;
}

.scrolltaskList .dropdown {
  bottom: 13px;
}

.nav-tabs.lenders-tab-panel {
  border-bottom: 1px solid #fff;
}

#opportunitySummary .persondetail {
  top: 18px;
  position: relative;
  display: inline-block;
}

.modal-dialog.modal-md .modal-content {
  max-width: 540px;
}

.add-schedule-link {
  cursor: pointer;
  color: #0f669e;
  font-weight: 700;
}

/* .pagetitle.headerTitle{
    padding: 33px 0px 32px 31px;
} */

.pagetitle.pagetitle.headerTitle img {
  padding-bottom: 2px;
}

.pagetitle.pagetitle.headerTitle h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.8);
  padding: 0 0 0 35px;
}

/* .printBtn{padding-top: 33px} */

.address-div.font {
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.3px;
  color: #000000;
  background: #fff;
}

.summaryDescription label {
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.8);
  opacity: 0.6;
}

.summaryDescription p {
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.8);
}

.searchbargray.market {
  margin-top: 19px;
  right: 0;
}

.searchbargray.lender-search {
  margin-top: 19px;
  right: 0;
}

.middleText {
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #98a9bc;
}

.notify.bottonDesc {
  padding-top: 80px;
}

/* */

.approvebtn {
  line-height: normal;
}

.print-package {
  padding: 8px;
}

.security-button {
  padding: 8px;
  width: 70%;
}

.accessbtnColor {
  background-color: #eb5757;
  border-color: #eb5757;
}

.security-button.accessbtnColor.unsuspend {
  border-color: #13bd24;
  background: #13bd24;
}

.accessbtnColor:hover {
  background-color: #eb5757;
  border-color: #eb5757;
}

#reopen .borderbtn {
  border: 1px solid #0f669e;
  border-radius: 4px;
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  align-items: center;
  text-align: center;
  color: #0f669e;
  padding: 8px 12px;
}

#reopen .solidbtnxs {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  align-items: center;
  text-align: center;
  background: #0f669e;
  border-radius: 4px;
  border: 1px solid #0f669e;
  padding: 8px 12px;
  color: #ffffff;
}

#reopen .primaryanchor {
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.1px;
}

#reopen .borderbtn {
  border: 1px solid #0f669e;
  border-radius: 4px;
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  align-items: center;
  text-align: center;
  color: #0f669e;
  padding: 8px 12px;
}

#reopen .solidbtnxs {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  align-items: center;
  text-align: center;
  background: #0f669e;
  border-radius: 4px;
  border: 1px solid #0f669e;
  padding: 8px 12px;
  color: #ffffff;
}

#reopen .primaryanchor {
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.1px;
}

.notification-content .position {
  display: inline;
}

.notification-content .messageStatus {
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 22px;
  color: #0f669e;
}

.notification-content .messageStatus img {
  position: relative;
  bottom: 1px;
}

.notification-content .displayBlock {
  display: block;
}

.borderbtn.contact {
  /* width: 251px; */
  background: transparent;
  font-family: HelveticaNeue;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.1px;
  color: #0f669e;
  text-align: center;
  height: 51px;
  border-radius: 0;
}

#reassignTask .form-label {
  font-family: HelveticaNeue;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.8);
}

#reassignTask .btnPadding {
  padding-top: 120px;
}

#activeOpportunity .table-responsive {
  min-height: 90vh;
}

.all-opportunities .table-responsive {
  min-height: 380px;
}

#activeOpportunity .checkbox-left {
  position: absolute;
  z-index: 100;
  width: 281px;
  min-height: 264px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(158, 158, 158, 0.25);
  border-radius: 10px;
  padding: 23px 11px;
  margin: 8px 0 0 0;
}

#activeOpportunity .checkbox-left .custom-checkbox .custom-control-label::before {
  border: 1px solid #8b96a3 !important;
  border-radius: 1px !important;
}

.min-page-height {
  min-height: 80vh;
}

.lender-card {
  background: #fff;
  padding: 15px;
  box-shadow: 0px 4px 12px rgba(102, 102, 102, 0.21);
  color: #44566c;
  border-radius: 6px;
  line-height: 37px;
  margin-bottom: 1em;
}

.lender-card-header {
  padding: 15px;
}

.reject-file {
  border: 2px solid red !important;
  opacity: 0.2;
  cursor: disabled !important;
}

.line-height-37 {
  line-height: 37px;
}

#edit-profile .previewList .btn-danger {
  top: -5px;
  left: auto;
  right: -5px;
}

#messageLender .chat-conversation {
  margin: 15px 33px 0 33px;
}

#messageLender .chat-conversation .chat-scroller {
  max-height: 90vh;
  min-height: 50vh;
  height: calc(100vh - 330px);
}

#opportunityLender .primarybtn {
  font-family: HelveticaNeue medium;
  width: 184px;
  height: 48px;
}

/* .fa-sort-down, .fa-sort-up{position:absolute;} */

.condition {
  padding: 30px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.condition:last-child {
  border-bottom: 0;
}

.hide {
  position: relative;
  float: right;
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  text-transform: capitalize;
  color: #0065f2;
  background: #d9ecff;
  border: 1px solid #d9ecff;
}

.seprate {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  margin: 1px;
}

.condition.list {
  padding-top: 15px;
}

.condition .nameCondition {
  font-family: HelveticaNeue medium;
  font-style: normal;
  font-size: 15px;
  line-height: 19px;
  text-transform: capitalize;
  color: #44566c;
}

.condition .conditionDetails {
  font-family: HelveticaNeue;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 19px;
  text-transform: capitalize;
  color: #44566c;
  margin-bottom: 20px;
}

.condition .conditionDetails span {
  font-weight: 400;
  font-family: HelveticaNeue;
}

.condition .btn-link {
  font-size: 15px;
  color: #0f669e;
  line-height: 19px;
  font-family: HelveticaNeue medium;
}

.condition .conditionAmount {
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 40px;
  text-transform: capitalize;
  color: #44566c;
}

#saveConfirmation .modal-body,
#inviteConfirm .modal-body,
#borrowerConfirm .modal-body {
  padding: 0 52px;
}

#saveConfirmation .btn {
  border-radius: 0;
}

#saveConfirmation .marginTop {
  margin-top: 65px;
}

.pagetitle.heading a {
  color: rgba(0, 0, 0, 0.8);
}

.primarybtn.back {
  top: 9px;
  position: relative;
}

.fa-asterisk {
  font-size: 8px;
  position: relative;
  bottom: 4px;
}

.publish {
  width: 203px;
  height: 53px;
}

.next {
  width: 203px;
  height: 53px;
  text-align: center;
  border: 2px solid #0f669e;
}

@media (min-width: 320px) and (max-width: 767px) {
  .next {
    width: 150px;
    height: 40px;
    text-align: center;
    border: 2px solid #0f669e;
    margin-bottom: 20px;
  }
}

.boxDropdown {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  padding-bottom: 21px;
  padding-top: 16px;
}

.boxDropdown .dropdown-item {
  font-family: Helvetica Neue;
  font-size: 15px;
  line-height: 18px;
  text-transform: capitalize;
  color: #8697a8;
  padding-top: 6px;
  padding-bottom: 6px;
}

.boxDropdown .dropdown-item:hover {
  background: transparent;
}

.sortfield {
  float: right;
  padding-right: 10px;
}

.sharedDocFont {
  white-space: nowrap;
  overflow-x: hidden;
}

.closedDealReopened.padding {
  padding-top: 22px;
}

.card-description.network {
  padding: 108px 0.5px 0;
  width: 350px;
  margin: 0 auto;
}

.card-description.network h4 {
  font-family: HelveticaNeue bold;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.1px;
  text-transform: capitalize;
  color: #98a9bc;
  margin-bottom: 12px;
}

.card-description.network p {
  font-family: HelveticaNeue medium;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #98a9bc;
  margin: 15px auto 25px auto;
  max-width: 270px;
}

.primarybtn.network {
  height: 51px;
  width: 203px;
}

.lender-card .rounedtxt {
  display: inline-block;
  font-family: HelveticaNeue medium;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  text-align: center;
  color: #0f669e;
  width: 150px;
}

.lender-card .primarybtn {
  min-width: 128px;
}

.lender-card .name {
  text-transform: capitalize;
}

.lender-card .name,
.lender-card .email {
  font-family: HelveticaNeue;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  align-items: center;
  color: #000000;
}

#connection-confirmation .network .centertxtbold {
  margin: 35px 0 65px 0;
  padding: 0 49px;
}

#connection-confirmation .network {
  padding: 0 52px;
}

.no-text-transform {
  text-transform: none !important;
}

.grecaptcha-badge {
  visibility: hidden;
}

.network.bg-white {
  font-family: HelveticaNeue;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #44566c;
  min-width: 192px;
  height: 41px;
  padding: 12px;
}

.selectdropdown {
  height: 48px;
  border: none;
  min-width: 70px;
  font-size: 0.8rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-repeat: no-repeat;
  background-position-x: calc(100% - 1rem);
  background-position-y: 20px;
}

.selectdropdown:focus {
  border: none !important;
}

.input-group-append {
  margin-left: 0px;
}

#createConfirmation .centertxtbold {
  padding: 20px 10%;
}

.general {
  display: flex;
  justify-content: space-between;
}

.generalCardInfo {
  position: relative;
  background: #ffffff;
  border: 1px solid #e5eaf4;
  box-sizing: border-box;
  box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
}

.generalCardInfo2 {
  position: relative;
  width: 31%;
  margin: 0 1% 0 1%;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 16px;
  font-family: MuseoSans;
}

.generalCardInfo3 {
  position: relative;
  width: 30%;
  margin: 0 1% 0 1%;
  background: #ffffff;
  border: 1px solid #e5eaf4;
  box-sizing: border-box;
}

.generalCardInfo {
  position: relative;
  background: #ffffff;
  border: 1px solid #e5eaf4;
  box-sizing: border-box;
  box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
}

.generalCardInfo3 {
  position: relative;
  background: #ffffff;
  border: 1px solid #e5eaf4;
  box-sizing: border-box;
  box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
}

.generalCardInfo span {
  margin-top: 10%;
  font-style: normal;
  font-weight: bold;
  font-family: MuseoSans;
  font-size: 22px;
  line-height: 32px;
  /* identical to box height, or 145% */
  text-align: center;
  letter-spacing: 0.2px;
  color: #183b56;
  display: block;
}

.generalCardInfo2 h5 {
  font-family: MuseoSans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  padding-left: 22%;
  text-align: left;
  color: #5a7184;
}

.generalCardInfo3 span {
  margin-top: 10%;
  font-style: normal;
  font-weight: bold;
  font-family: MuseoSans;
  font-size: 22px;
  line-height: 32px;
  /* identical to box height, or 145% */
  text-align: center;
  letter-spacing: 0.2px;
  color: #183b56;
  display: block;
}

.play-btn {
  position: absolute;
  z-index: 666;
  top: 57%;
  left: 47%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  border: 0;
}

.play-btn:hover {
  cursor: pointer;
}

.play-btn:focus {
  outline: 0;
}

.generalCardInfo2 span {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 32px;
  /* identical to box height, or 145% */

  text-align: left;
  padding-left: 22%;
  padding-bottom: 10px;
  letter-spacing: 0.2px;
  color: #183b56;
  display: block;
}

.generalCardInfo h5 {
  font-family: MuseoSans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  /* or 175% */
  color: #5a7184;
  text-align: center;
}

.generalCardInfo3 h5 {
  font-family: MuseoSans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  /* or 175% */
  color: #5a7184;
  text-align: center;
}

.generalCardInfo .generalLink {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  /* identical to box height, or 175% */

  text-align: center;
  text-decoration-line: underline;

  color: #5a7184;
  padding-bottom: 5%;
}

.generalCardInfo3 .generalLink {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  /* identical to box height, or 175% */

  text-align: center;
  text-decoration-line: underline;

  color: #5a7184;
  padding-bottom: 5%;
}



.generalCardImage {
  position: absolute;
  width: 64px;
  height: 64px;
  left: 41%;
  top: -17%;
}

.generalPlayButton {
  position: absolute;
  width: 64px;
  height: 64px;
  left: 43%;
  top: -17%;
  z-index: 98 !important;
}

.thumbnailVideo {
  /* position: absolute; */
  width: 526px;
  height: auto;
  /* left: 43%;
top: -17%; */
}



@media (min-width: 320px) and (max-width: 767px) {
  .thumbnailVideo {
    width: 320px;
    height: auto;
  }

  .generalCardImage {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 41%;
    top: -8% !important;
  }

  .generalPlayButton {
    margin: -24px 0 0 -16px;
    position: absolute;
    width: 50px;
    height: 50px;
    left: 43%;
    top: -17%;
  }
}

.generalCardImage2 {
  position: absolute;
  width: 48px;
  height: 48px;
  left: 0%;
  top: -3%;
  padding: 7px;
  border: 1px solid rgba(21, 101, 216, 0.2);
  border-radius: 16px;
}

.emojidiv .dragBox {
  display: inline;
}

.sort-select {
  width: 250px;
}

.sort-select .dropdown-menu {
  min-width: 250px;
}

::-webkit-input-placeholder {
  padding: 0 !important;
  display: block;
}

:-moz-placeholder {
  /* Firefox 18- */
  padding: 0 !important;
  display: block;
}

::-moz-placeholder {
  /* Firefox 19+ */
  padding: 0 !important;
  display: block;
}

:-ms-input-placeholder {
  padding: 0 !important;
  display: block;
}

.file-note-check {
  opacity: 0;
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.borrower-note-check {
  opacity: 0;
}

.outer-container input:focus+.file-note-check {
  opacity: 1;
}

.outer-container input:focus+.borrower-note-check {
  opacity: 1;
}

.react-datepicker-wrapper {
  display: block;
}

#popover-basic {
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
}

#popover-basic.popover.bs-popover-bottom {
  position: absolute;
  top: 0px;
  left: 0px;
  margin: 0px;
  right: auto;
  bottom: auto;
  transform: translate(1261.33px, 60px) !important;
  max-width: 300px;
  height: 200px;
  border: none;
  box-shadow: 0px 2px 16px rgba(153, 155, 168, 0.12);
  border-radius: 4px;
}

#popover-basic .row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

#popover-basic .row:last-child {
  border-bottom: 0;
}

#popover-basic .notification {
  padding: 15px;
  width: 270px;
  border-bottom: 1px solid #d6d6d6;
}

.nav-item .arrow-up,
.arrow-up {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white;
  position: absolute;
  top: -9px;
  right: -220px;
  z-index: 100000;
}

#popover-basic .notification p {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #1b1e24;
  padding-bottom: 4px;
}

#popover-basic .notification span {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.2px;
  color: #98a9bc;
}

.count {
  position: absolute;
  top: -1px;
  right: 3px;
  font-size: 9px;
  background: red;
  padding: 3px;
  height: 20px;
  text-align: center;
  border-radius: 50%;
  width: 20px;
}

/* datepicker */
.react-datepicker-popper {
  /* left: 48% !important; */
}

.react-datepicker__header {
  background-color: #f8fafb;
}

.react-datepicker__navigation {
  top: 30px;
}

.react-datepicker__header {
  border-bottom: 0px;
}

.react-datepicker-popper[data-placement="bottom-end"] .react-datepicker__triangle {
  left: 90% !important;
  border-bottom-color: #fff;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-family: HelveticaNeue medium;
  font-style: normal;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  text-transform: capitalize;
  color: #44566c;
  background-color: #f8fafb;
  padding: 22px 0;
  border-bottom: 1px solid #cacaca;
}

.react-datepicker__day-names {
  white-space: nowrap;
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  text-transform: uppercase;
  padding: 14px 0 11px 0;
  background: #fff;
}

.react-datepicker__day-name {
  font-family: HelveticaNeue medium;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
  color: #8697a8;
}

.react-datepicker {
  border: none;
}

.create-bottom {
  margin-bottom: 170px;
}

.modal-dialog .alert {
  font-size: 20px;
  margin-bottom: 10px;
  line-height: 1.5;
}

.col-5.email.width {
  flex: 0 0 40%;
}

/*Style for file preview start */
.msg-drop-box-img {
  float: left;
  padding: 5px 10px;
}

.msg-drop-box-name {
  float: left;
  padding: 8px 10px 8px 5px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  white-space: nowrap;
  color: #44566c;
}

.msg-drop-box-name .size {
  color: #8697a8;
  font-size: 13px;
  line-height: 20px;
  padding-top: 5px;
}

/*Style for file preview end */

.marginSetup {
  margin-top: 15%;
}

.scrollingcontainer {
  max-height: 600px;
  overflow-y: auto;
  padding: 10px;
  color: #000;
}

.scrollingcontainer b {
  font-family: HelveticaNeue medium;
  font-weight: 100;
}

.scrollingcontainer li {
  padding: 5px 0;
}

.scrollingcontainer strong {
  font-family: HelveticaNeue medium;
  font-weight: 100;
}

.borderbox {
  font-family: HelveticaNeue medium;
  border: 1px solid #000;
  padding: 19px;
  margin-bottom: 20px;
}

.borderbox2 {
  border: 1px solid #000;
  padding: 19px;
  margin-bottom: 20px;
}

.scrollingcontainer h4 {
  color: #000;
  font-family: HelveticaNeue medium;
  font-size: 20px;
  margin: 20px 0;
}

.scrollingcontainer .underline {
  text-decoration: underline;
}

.profile-header {
  padding-left: 25%;
  font-size: 24px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.8);
}

/*Align close button verically on reuable modal*/
button.close {
  line-height: 0.4 !important;
}

.blog-empty {
  background: #dce8f5;
  margin: 0 auto;
  vertical-align: middle;
  border-radius: 8px;
  /* padding: calc(50% - 150px); */
  text-align: center;
}

/* .blog-empty img {
	width:300px;
} */
.edit-image {
  background: #000000 !important;
  position: absolute;
  top: 285px !important;
  opacity: 0.7;
}

.edit-image a {
  color: #ffffff;
  font-size: 16px !important;
  line-height: 25px !important;
  text-decoration: underline;
  padding-top: 10px;
  display: block;
}

.image-modal-header {
  text-align: center;
  width: 100%;
  border-bottom: none;
}

.image-modal-header h1 {
  text-align: center;
  width: 100%;
  margin: 0;
  padding: 0;
}

.update-img-btn {
  width: 200px;
}

.thumbsContainer {
  padding: 0px;
  /* margin: 0px; */
  list-style: none;
}

.previewList .remove-icon {
  display: none;
}

.previewList {
  cursor: move;
}

.previewListRotate {
  -ms-transform: rotate(-10deg);
  /* IE 9 */
  transform: rotate(-10deg);
}

.previewList:hover .remove-icon {
  display: block;
}

.remove-icon {
  color: #ffffff;
  position: absolute;
  top: 70%;
  right: 0px;
  font-size: 14px;
  padding: 5px;
  display: block;
  width: 100%;
  background: #000000;
  opacity: 0.7;
  cursor: pointer;
  font-weight: 600;
  letter-spacing: 0.1rem;
}

.remove-icon:hover {
  color: #ffffff;
}

.terms-box {
  width: 300px;
  margin: 0 auto;
  text-align: left;
}

.terms-box .custom-control-label {
  font-size: 16px;
}

.terms-box .link-text {
  font-size: 18px;
  font-family: HelveticaNeue medium;
  font-style: normal;
  font-weight: 500;
}

.fixedFooter {
  position: fixed;
  bottom: 0;
}

.editImgBtn {
  font-size: 16px;
  font-weight: 800;
  line-height: 16px;
}

.editImgBtn span {
  font-size: 20px;
}

.primary-image {
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
  color: #ffc800;
  font-size: 20px;
}

.minPasswordValid {
  color: black;
  float: right;
  font-size: 14px;
}

.minPasswordInValid {
  color: red;
  float: right;
  font-size: 14px;
}

.columnAdmin {
  position: relative;
  float: left;
  width: 50%;
  padding: 30px 10px 10px 20px;
  height: 150px;
  /* Should be removed. Only for demonstration */
}

.profile-img-admin {
  width: 110px;
}

.profile-img-name {
  /* position:absolute; */
  left: 26%;
  top: 35%;
  color: rgba(0, 0, 0, 0.8);
  margin-right: 30px;
  line-height: 24px;
  font-weight: normal;
  font-size: 24px;
  font-weight: 500;
}

.profile-img-name.updated {
  color: #0F669E;
  font-weight: 500;
}

.profile-img-name.updated small {
  font-weight: 400;
  font-size: 14px;
}

.profile-img-role {
  /* position:absolute; */
  left: 26%;
  top: 53%;
  color: rgba(0, 0, 0, 0.8);
  line-height: 24px;
  margin-right: 30px;
  font-size: 18px;
  font-weight: 200;
  text-transform: capitalize;
}

.profile-img-status {
  /* position:absolute; */
  left: 26%;
  top: 25%;
  color: black;
  margin-right: 30px;
  margin-top: 5px;
  font-weight: 600;
  color: #f2994a;
  font-family: Helvetica Neue;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
}

.profile-img-status-verifed {
  left: 26%;
  top: 25%;
  color: rgba(29, 148, 9, 0.8);
  margin-right: 30px;
  margin-top: 5px;
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
}

.columnAdminTitle {
  position: relative;
  float: left;
  margin-top: 30px;
  width: 20%;
  padding: 10px;
  height: 150px;
  /* Should be removed. Only for demonstration */
  border-bottom: 1px solid #c4c4c4;
}

.columnAdminField {
  position: relative;
  margin-top: 30px;
  float: left;
  width: 40%;
  padding: 10px;
  height: 150px;
  /* Should be removed. Only for demonstration */
  border-bottom: 1px solid #c4c4c4;
}

.columnAdmin .adminApprove {
  /* position: absolute; */
  /* right:0; */
  float: right;
  padding: 10px 39px 10px 39px;
  margin-top: 50px;
  margin-left: 20px;
  margin-right: 30px;
}

.columnAdmin .adminReject {
  /* position: absolute; */
  /* right:0; */
  float: right;
  margin-left: 20px;
  padding: 10px 39px 10px 39px;
  margin-top: 50px;
  margin-right: 210px;
}

.rowAdmin {
  border-bottom: 1px solid #c4c4c4;
}

/* Clear floats after the columns */
.rowAdmin:after {
  content: "";
  display: table;
  clear: both;
}

.userAdmin .tabs-bg {
  background: none;
  box-shadow: none;
  z-index: 100;
  padding: 20px 70px;
}

.userAdmin .tabs-bg .nav-tabs .nav-item.upper {
  min-width: 33.3%;
}

.button-inline {
  display: inline;
}

.pending-table .tablerow {
  border-bottom: 1px solid #f2f4f6;
}

.profileSection {
  padding: 30px 0 30px 0;
  border-bottom: 1px solid #c4c4c4;
}

.user-search.user {
  display: inline;
  top: 0;
}

.pagination {
  background-color: #ffffff;
  padding: 5px;
  margin-top: 20px;
  color: #252631;
  font-size: 13px;
}

.pagination .page-item {
  color: #252631;
  border: none;
  background-color: #ffffff;
  border-radius: 4px;
  width: 30px;
  font-size: 13px;
  height: 30px;
  margin: 3px;
}

.pagination .page-item:focus {
  border: none;
}

.pagination .active {
  background-color: #0f669e;
  color: #ffffff;
}

.pagination span {
  padding: 5px;
}

.admin-table .table-heading th {
  overflow-x: none !important;
  white-space: nowrap;
}

.font-15.heading-table.padding {
  padding-left: 35px;
}

.status {
  padding: 5px;
  font-size: 14px;
  text-transform: capitalize;
  border-radius: 6px;
  text-align: center;
  font-weight: 500;
  white-space: nowrap;
}

.profile-status {
  text-transform: uppercase;
}

.status-Published,
.status-Verified {
  background: #d3eadd;
  color: #219653;
}

.status-Invited {
  background: #a0e9f2;
  color: #1aaec1;
}

.profile-status-Verified {
  color: #219653;
}

.status-Pending-Approval {
  background: #cfe1ec;
  color: #0f669e;
}

.status-Draft,
.status-Pending-Verification,
.status-Pending-Profile-Changes,
.status-Profile-not-Completed {
  background: #fee2b5;
  color: #ff9900;
}

.profile-status-Draft,
.profile-status-Pending,
.profile-status-Pending-Verification,
.profile-status-Pending-profile-change,
.profile-status-Profile-not-Completed {
  color: #ff9900;
}

.status-Failed-Verification {
  background: rgba(242, 153, 74, 0.2);
  color: #f2994a;
}

.profile-status-Failed-Verification {
  color: #f2994a;
}

.status-Closed {
  background: #e9e9e9;
  color: #000000;
}

.status-Pending-Review,
.status-Deactivated {
  background: #e4d3ea;
  color: #a258f6;
}

.status-Funded-on,
.Fundever,
.status-Funded-Elsewhere {
  background: #e4d4ea;
  color: #0f669e;
}

.status-Suspended {
  color: #eb5757;
  background-color: #fbdddd;
}

.table .form-control {
  background: #f0f0f1 !important;
  border-radius: 2px;
}

.table td {
  color: #252631;
  font-size: 14px;
}

.width-90 {
  width: 100%;
}

.admin-table .table-heading th {
  overflow: unset;
}

#wrapper #content-wrapper {
  /* width: 83%; */
}

.popover.bs-popover-below {
  max-width: initial;
  min-height: 138px;
  transform: translate(274px, -314px) !important;
  top: unset !important;
}

#popover-amount.popover.bs-popover-below {
  max-width: initial;
  min-height: 138px;
  transform: translate(274px, -314px) !important;
  top: unset !important;
}

.dropdownwhite {
  -webkit-appearance: none;
  background: #fff;
  display: block;
  margin-top: 6px !important;
}

@media (min-width: 1100px) and (max-width: 1440px) {
  .sidebar {
    width: 18% !important;
  }

  #wrapper #content-wrapper {
    /* width: 100%; */
  }

  .sidebar .nav-item .nav-link {
    width: 100% !important;
  }

  .admin-table .rdt {
    position: relative;
    width: 126px;
    margin-right: 13px;
    margin-left: 6px;
  }
}

.active-card {
  background-color: #e4ebff;
}

.dropdown-wrapper {
  position: relative;
  width: 100%;
}

.dropdown-wrapper .label {
  width: 100%;
}

.dropdown-wrapper .dropdown-body {
  position: absolute;
  top: 30px;
  min-height: 20px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 1000;
  border-radius: 4px;
}

.min-table-height {
  min-height: 600px;
}

.color-black {
  color: "black";
}

/*new*/
@media (min-width: 1200px) and (max-width: 1400px) {
  .howItWorks {
    float: left;
    width: 50%;
    padding: 0 8% 0 3%;
  }
}

@media (max-width: 390px) {
  .banner__caption h1 {
    margin-bottom: 6%;


  }
}

@media (max-width: 600px) {

  .blueheader .navbar-light .navbar-toggler-icon:after,
  .navbar-light .navbar-toggler-icon:before {
    background-color: #0f669e;
  }

  .banner__caption h1 {
    font-size: 26px;

  }

  .banner__caption_general h1 {
    font-size: 19px;
  }

  .generalCardInfo {
    width: 100%;
    margin-bottom: 32px;
  }

  .generalCardInfo2 {
    width: 100%;
    margin-bottom: 32px;
  }

  .generalCardInfo3 {
    width: 100%;
    margin-bottom: 32px;
  }

  .deals {
    padding: 35px 0px;
  }

  .imgmbwidth {
    width: 100%;
  }

  .deals table td {
    min-width: 108px;
    text-align: left;
    padding-left: 0px;
  }

  .cta {
    padding: 13px 0;
  }

  .cta__title {
    font-size: 30px;
    margin-bottom: 15px;
    padding: 25px 0;
    text-align: center;
  }

  .generalCardInfo h5 {
    font-size: 15px;
    line-height: 24px;
  }

  .generalCardInfo3 h5 {
    font-size: 15px;
    line-height: 24px;
  }

  footer {
    padding-bottom: 10px;
  }

  .howItWorksImage {
    width: 100%;
  }

  .fundevertxt {
    padding: 20px 0px;
    padding-bottom: 55px;
    margin-top: 1rem !important;
  }

  .fundever_button {
    text-align: center;
    padding: 10px 20px;
    font-size: 16px;
  }

  .fundever_bodytxt {
    text-align: center;
  }

  .banner__caption_lender {
    bottom: unset;
    top: 117px;
  }

  .top-right {
    position: relative;
    right: 0px;
    top: 34px;
    bottom: 0px;
    top: 0px;
    width: 100%;
  }

  .contactBgImage {
    padding: 10px 12px;
  }

  .contactBgImage {
    background-repeat: no-repeat;
  }

  .dekstop-top-65 {
    padding-top: 40px;
  }

  .header-scrolled-home .navigation-wrap {
    background: #0f669e;
  }

  .header-scrolled-home .navbar-light .navbar-toggler-icon:after {
    border-bottom: 2px solid #fff;
  }

  .header-scrolled-home .navbar-light .navbar-toggler-icon:before {
    background-color: #fff;
  }

  .header-scrolled-home .navbar-light .navbar-toggler-icon {
    border-bottom: 2px solid #fff;
  }

  .header-scrolled-home .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
    border-color: transparent;
  }

  .introduction_text_right {
    text-align: center;
    padding: 5px;
  }

  .blueheader {
    padding-bottom: 0px;
  }

  .breadcrumbsec h1 {
    margin-bottom: 0px;
  }

  /* .start-header {
    padding: 0px 13px;
  } */

  .pagetitle-header {
    margin-top: 0px;
  }

  .loginuser {
    padding: 10px 10px;
  }

  .whitebtn {
    background: #0f669e;
    /* float: right; */
    padding: 4px 6px;
    margin-top: 7px;
    font-size: 12px;
    line-height: 11px;
    margin-right: 0px !important;
    color: #fff;
    border-radius: 2px !important;
    color: #fff !important;
    position: absolute;
    right: 8px;
    top: 45px;
    z-index: 9999;
  }

  iframe {
    width: 100%;
    height: 16em;
  }

  .blueheader .navbar-light .navbar-toggler-icon:after,
  .blueheader .navbar-light .navbar-toggler-icon:before {
    background-color: #fff;
  }
}

@media (min-width: 601px) and (max-width: 768px) {
  .howItWorks {
    float: left;
    width: 100%;
  }

  .howItWorks {
    float: left;
    width: 50%;
    padding: 50px 25px 0 25px;
  }

  .howItWorks {
    width: 100%;
  }

  .howItWorksImage {
    width: 100%;
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
  .imgmbwidth {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .howItWorks {
    padding: 26px 25px 0 25px;
  }

  .dekstop-top-65 {
    padding-top: 55px;
  }

  .breadcrumbsec {
    /* background: url(../img/breadcrumb-bg.png); */
    padding: 30px 30px;
    background-repeat: no-repeat;
    margin-bottom: 20px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .sidebar {
    position: fixed;
    z-index: 250;
    top: 40px;
    padding-top: 10px;
  }
}

.navbar-nav .dropdown-menu {
  position: absolute;
  float: unset;
  background: #fff;
  position: absolute;
  width: 100%;
  z-index: 111;
}

@media (max-width: 600px) {
  .whitebtn {
    z-index: 111;
  }
}

.max-width-container {
  max-width: 1400px;
  margin: auto;
}

.setup-profile-txt {
  max-width: 380px;
  margin: auto;
}

.title-list {
  font-style: normal;
  font-family: HelveticaNeue bold;
  font-size: 20px;
}

.bluecolor.padding,
.paddingExtra {
  padding-right: 35px;
}

.custom-popover .arrow {
  top: calc(50% - 0.8rem);
}

.user .edit {
  position: relative;
}

.user .edit .btn-link {
  position: absolute;
  top: 7px;
  right: 0;
  text-align: right;
  font-family: HelveticaNeue medium;
  font-size: 14px;
}

.user .reactdatepicker .form-control {
  border-radius: 0;
}

.card.spec {
  background-color: #fff;
  background-clip: border-box;
  border: 0;
  border-radius: 0;
}

.spec .inputtable td,
.spec .font-600 {
  color: #000;
}

#buildingSpecification .modal-content {
  width: 850px;
}

.spec .flex {
  display: flex;
  justify-content: flex-end;
}

.darkgraybg.ownership {
  text-transform: capitalize;
}

.card .card-header.collapsed::after {
  position: absolute;
  right: 0;
  top: 0;
  padding-right: 1.725rem;
  font-weight: 900;
  content: "\f105";
  font-family: FontAwesome;
  font-size: 30px;
  line-height: 1.9;
  color: #000;
}

.card .card-header::after {
  position: absolute;
  right: 0;
  top: 0;
  padding-right: 1.725rem;
  font-weight: 900;
  content: "\f107";
  font-family: FontAwesome;
  font-size: 30px;
  line-height: 1.9;
  color: #000;
}

.closeButton {
  background: url("../img/DropClose.svg") no-repeat;
  border: none;
  height: 16px;
  width: 16px;
}

.outerPending .blue-head {
  padding: 30px 15px;
  font-family: HelveticaNeue medium;
  background: #0f669e;
  color: #fff;
  font-size: 15px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.outerPending .section {
  padding: 20px 15px;
  font-family: HelveticaNeue medium;
  color: #666;
}

.outerPending .section div {
  line-height: 45px;
}

.pending-review-table .admin-table .table-head {
  background: #fff;
  color: #0f669e;
  font-family: HelveticaNeue medium;
  border-bottom: 1px solid #dddfeb;
}

.fundeverVideo {
  width: 55%;
  height: 336px;
  margin: 0;
  padding: 0 20px 20px 20px;
  z-index: 100;
}

@media (min-width: 320px) and (max-width: 720px) {
  .fundeverVideo {
    width: 100%;
    height: 185px;
    margin: 0;
    padding: 0 10px 10px 10px;
  }
}

.ytp-chrome-top {
  display: none !important;
}

.ytp-chrome-top .ytp-show-cards-title {
  display: none !important;
}

.text-capitalized {
  text-transform: capitalize;
}

.filter-bar .custom-control-label {
  font-family: HelveticaNeue;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #707985;
}

.filter-bar .form-group {
  margin-bottom: 8px;
}

.filter-bar .react-datepicker__header {
  background-color: #fff;
}

.filter-bar .react-datepicker__navigation--previous {
  left: 20%;
}

.filter-bar .react-datepicker__navigation--next {
  right: 20%;
}

.filter-bar .react-datepicker__day--keyboard-selected {
  border-radius: 1rem;
}

.filter-bar .react-datepicker__day-name,
.filter-bar .react-datepicker__day,
.filter-bar .react-datepicker__time-name {
  margin: 0.25rem 0.65rem;
  font-size: 15px;
}

.filter-bar .react-datepicker__day-names {
  padding: 0 20px;
  border-bottom: 1px solid #e8ecef;
}

.filter-bar .react-datepicker__current-month {
  border: 0;
  background-color: #fff;
  padding: 22px 0 14px;
}

.filter-bar .react-datepicker__day-name {
  color: #000;
}

.filter-bar .solidbtnxs {
  width: 100%;
  height: 54px;
  border-radius: 5px;
}

.filter-bar .react-datepicker__day--selected {
  border-radius: 1rem;
}

.filter-bar .dateDisplay {
  color: #000;
}

.filter-bar .closeButton {
  vertical-align: middle;
  margin-left: 15px;
}

ul.region-list {
  list-style: none;
  padding: 10px;
  max-height: 400px;
  text-align: left;
  overflow-y: auto;
  width: 100%;
}

ul.region-list li {
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #778ca2;
  padding: 10px 0px;
}

ul.region-list li ul {
  list-style: none;
  padding: 0;
  margin: 0px;
}

ul.region-list li ul li {
  font-size: 16px;
  line-height: 17px;
  color: #000000;
  padding: 7px 0px;
}

ul.region-list li ul li:first-child {
  padding-top: 14px;
}

ul.region-list li ul li .active {
  color: #0f669e;
}

ul.region-list li ul li .closeButton {
  margin-top: -16px;
}

.bottomSec {
  font-family: HelveticaNeue medium;
}

.sortdropdown {
  max-width: 300px;
  float: right !important;
  padding-top: 10px;
}

.market-opportunities .searchbargray {
  float: right;
  top: 6px;
  margin-right: 0px !important;
}

.thirty {
  width: 30%;
}

.sixty {
  width: 60%;
}

.twenty {
  width: 20%;
}

.twenty-two {
  width: 22%;
}

.fifteen {
  width: 15%;
}

.ten {
  width: 10%;
}

.twelve {
  width: 12%;
}

.five {
  width: 5%;
}

.emoji-icon {
  position: relative;
}

.emoji-picker {
  position: absolute;
  width: 550px;
  height: 150px;
  padding: 0px;
  top: -160px;
  left: -8px;
  background: #ffffff;
  border: #cccccc solid 1px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  text-align: left;
  padding: 10px;
}

.emoji-picker:after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 10px;
  border-style: solid;
  border-width: 10px 10px 0;
  border-color: #ffffff transparent;
  display: block;
  width: 0;
  z-index: 1;
}

.emoji-picker:before {
  content: "";
  position: absolute;
  top: 149px;
  left: 10px;
  border-style: solid;
  border-width: 11px 11px 0;
  border-color: #cccccc transparent;
  display: block;
  width: 0;
  z-index: 0;
}

.image-attachment {
  width: 60px;
  margin-right: 20px;
  height: 60px;
  background: #f3f7f8 url(../img//icons/chat/attachment-image-icon.svg) center center no-repeat;
  box-shadow: 0 4px 4px 0 rgba(169, 194, 209, 0.05),
    0 8px 16px 0 rgba(169, 194, 209, 0.1);
  border-radius: 4px;
}

.more-attachment {
  background-color: #0f669e;
  color: #ffffff;
  font-weight: 600;
  height: 33px;
  width: 33px;
  line-height: 33px;
  text-align: center;
  border-radius: 100%;
  margin-top: 25px;
  margin-right: 25px;
  font-size: 13px;
}

button:focus,
img:focus,
a:focus,
.dragBox:focus,
input:focus {
  outline: none;
}

.msg-drop-box-name.dark,
.msg-drop-box-name.dark span {
  color: #ffffff !important;
}

.col-to-7 {
  flex: 0 0 14.28571%;
  max-width: 14.28571%;
}

.scrolltaskList.position .dropdown {
  position: unset;
}

.UserAvatar {
  display: inline-block;
}

.lenderDocument .drop-box-img {
  padding: 0 15px 0 0;
}

.shareddocument.brokerLender {
  width: 99% !important;
}

.shareddocument.brokerLender tbody {
  width: 100%;
  display: block;
  max-height: 400px;
  overflow-y: auto;
  min-height: 72vh;
}

.scrolltaskList.shared {
  height: 72vh;
}

.request {
  color: #f2994a;
}

.lenderDocument.shareddocument tbody {
  max-height: 90vh;
}

.dropdown-filter {
  background: #ffffff;
}

.dropdown-filter .custom-control {
  margin-bottom: 5px;
}

.dropdown-filter .custom-control-label {
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 25px;
  color: #000000;
}

.dropdown-filter .custom-control-label::before {
  border-radius: 1px !important;
}

.btn-reset {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  font-family: Helvetica Neue;
  text-decoration: underline;
  color: #0f669e;
  margin: 0px;
  padding: 0px;
}

.lenderDocument.shareddocument tbody {
  max-height: 90vh;
}

.fulfill .samewidth,
.conditional .btn-style,
#addCondition .samewidth {
  width: 190px;
  font-family: HelveticaNeue;
  border-radius: 4px;
}

.summary-description.conditional h4 {
  font-family: HelveticaNeue medium;
  font-size: 20px;
  line-height: 25px;
  color: #44566c;
  vertical-align: middle;
}

.summary-description.conditional .letter {
  display: inline-block;
  color: #44566c;
  text-decoration: underline;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  text-transform: capitalize;
}

.shareddocument.grid-change .col-2 {
  flex: 0 0 12%;
  max-width: 12%;
}

.shareddocument.grid-change .diff {
  flex: 0 0 13%;
  max-width: 13%;
}

.shareddocument.grid-change .heading-table2 {
  font-size: 15px;
}

@media screen and (max-width: 600px) {
  .shareddocument.grid-change .col-2 {
    min-width: 100%;
  }
}

.shareddocument.grid-change .dropdown {
  display: inline-block;
  position: relative;
  top: 2px;
  left: 60%;
}

.shareddocument .newwidth {
  width: 190px;
  font-family: HelveticaNeue;
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  padding: 0;
  height: 21px;
  display: block;
}

#addCondition .dragBox .primarybtn {
  height: 48px;
  border-radius: 4px;
}

.filter-bar .react-datepicker__day--keyboard-selected:focus {
  outline: none;
}

.filter-bar .react-datepicker__day--keyboard-selected {
  background: #fff;
  color: #000;
  font-weight: 400;
}

.primarytags.oppotunity-details.lenderOpp {
  top: 89px;
  right: auto;
  left: 40%;
}

.primarytags.oppotunity-details.lenderOpp .rounedtxt.dropdown-toggle {
  width: 198px;
}

.primarybtn.completed {
  background: rgba(15, 102, 158, 0.38);
  color: #0f669e;
  border: 0px solid rgba(15, 102, 158, 0.38);
  border-radius: 4px;
}

.lender-message {
  max-width: 300px;
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: #98a9bc !important;
  margin: auto;
  padding: 10px !important;
}

/*Firefox remove border form button*/
[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: none !important;
}

.shareddocument .dropdown-menu {
  z-index: 100;
}

.lenderDocument.shareddocument.brokerLender .remove {
  bottom: 1px;
}

.nounderline {
  text-decoration: none !important
}

.is-invalid {
  border: 1px solid #e74a3b !important;
}

.invalid-feedback {
  display: inline !important;
}

.extraWidth {
  width: 102%;
}

.lenderEmptyHeading {
  font-size: 20px;
  color: #98A9BC;
}

.lenderEmptyHeading p {
  font-size: 15px;
  color: #98A9BC;
}

.network.lenderSide .primarybtn,
.network.lenderSide .borderbtn {
  width: 120px;
}

.network.lenderSide .dropdown {
  display: inline;
}

.network.lenderSide .dropdown-toggle::after,
.menu-btn .dropdown-toggle::after {
  display: none;
}

.network.lenderSide .completed {
  text-transform: capitalize;
}

.members .custom-control-label {
  font-weight: 600;
  font-size: 15px;
  line-height: 23px;
  font-family: 'HelveticaNeue';
  display: flex;
  color: #0f669e;
}

.admin .primarytags {
  display: -webkit-flex;
  position: absolute;
  top: 13px;
  left: 0px;
}

.center-loader {
  width: 100%;
  height: 400px;
  padding-top: 130px;
}

.profileSection .custom-control-label::before,
.dropdown-body .custom-control-label::before {
  border-radius: 0px !important;
}

.profileSection .form-group,
.dropdown-body .form-group {
  margin-bottom: 0.4rem;
}

.lenderProfileList .dropdown-body {
  padding: 20px 0px 0px 0px;
}

.boldText {
  font-weight: bold;
  color: black;
}

.noCap {
  text-transform: none !important;
}

.modellingTitle {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.5px;
}

.sidebar {
  float: left;
}

#wrapper #content-wrapper {
  /* float: left; */
}

input:required,
select:required,
textarea:required {
  box-shadow: none;
}

input:invalid,
select:invalid,
textarea:invalid {
  box-shadow: none;
}

optgroup,
option {
  font-family: "Helvetica Neue" Arial;
  font-style: normal !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.updated-field {
  background: #d9ecff !important;
}

.blockList {
  background-image: url(../img/blockList.svg);
}

.blockListWhite {
  background-image: url(../img/blockListWhite.svg);
}

.listView {
  background-image: url(../img/listView.svg);
}

.listViewBlue {
  background-image: url(../img/listViewBlue.svg);
}

.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  box-shadow: 0 0 0 0.05rem #e74a3b;
}

.form-control.is-invalid {
  background-image: none
}

.equity_input {
  border: 0;
  color: #000;
  font-size: 15px;
  line-height: 17px;
  font-family: 'HelveticaNeue';
}

.pageSubtitle {
  color: #000;
  font-weight: 600;
  font-size: 13px;
}

.lenderEmptyHeading.market {
  font-size: 24px;
}

.card-description p.lenderMarket {
  font-size: 16px;
}

.login-container-margin {
  margin-bottom: 25vh;
}

.contact-from {
  margin-bottom: 8vh;
}

.faqBgImage {
  background-image: url('../img/breadcrumb-bg.png');
}

.top-bar {
  position: fixed;
  z-index: 2147483647;
  top: 0;
  left: -6px;
  width: 0%;
  height: 3px;
  background: #0f669e;
  -moz-border-radius: 1px;
  -webkit-border-radius: 1px;
  border-radius: 1px;
  -moz-transition: width 500ms ease-out, opacity 400ms linear;
  -ms-transition: width 500ms ease-out, opacity 400ms linear;
  -o-transition: width 500ms ease-out, opacity 400ms linear;
  -webkit-transition: width 500ms ease-out, opacity 400ms linear;
  transition: width 500ms ease-out, opacity 400ms linear;
  -webkit-appearance: none;
  transition: width 4s 1s;
}

.muteButton {
  margin: 0 0 25px 5px;
}

.top-padding span {
  padding-top: 10px;
}

.top-padding-doc span {
  padding-top: 2px;
  display: block;
}

.top-padding .actions-dropdown {
  padding-top: 4px;
}

.extraBannerPadding {
  padding: 50px !important;
}

#editImageModal.modal-dialog {
  margin: 10rem auto 0;
}

.swal2-container.swal2-top-end {
  top: 5%;
}

.form-control:disabled,
input:read-only {
  background-color: #cccccc !important;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 65px;
  height: 50px;
}

.sticky {
  transition: top 5s;
}

.modal-header .close {
  position: absolute;
  right: 1rem;
}

.transferBtn {
  background: #0F669E;
  border-radius: 4px;
  min-width: 135px;
}

#mergeAccount .modal-content {
  border-radius: 10px;
  padding: 15px;
}

.published-circle {
  background-color: #39e75f;
  width: 0.75em;
  height: 0.75em;
  border-radius: 50%;
}

.pending-circle {
  background-color: #ff8b3d;
  width: 0.75em;
  height: 0.75em;
  border-radius: 50%;
  animation: ease pending 1s infinite;
}

.live-circle {
  background-color: green;
  width: 0.75em;
  height: 0.75em;
  border-radius: 50%;
  animation: ease pulse 1s infinite;
}

.rec-child {
  display: inline-block;
  vertical-align: middle;
}

@keyframes pulse {
  0% {
    background-color: green;
  }

  50% {
    background-color: #39e75f;
  }

  100% {
    background-color: green;
  }
}

@keyframes pending {
  0% {
    background-color: orange;
  }

  50% {
    background-color: #ff6f00;
  }

  100% {
    background-color: orange;
  }
}