.password-strength-meter {
  text-align: left;
}

.password-strength-meter-progress {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 12px;
}

.password-strength-meter-progress::-webkit-progress-bar {
  background-color: #eee;
  border-radius: 3px;
}

.password-strength-meter-label {
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
}

.password-strength-meter-progress::-webkit-progress-value {
  border-radius: 2px;
  background-size: 35px 20px, 100% 100%, 100% 100%;
}

.strength-Weak::-webkit-progress-value {
  background-color: #B53629
}
.strength-Fair::-webkit-progress-value{
  background-color: #F2994A;
}
/* .strength-Good::-webkit-progress-value {
  background-color: #247BA0;
} */
.strength-Strong::-webkit-progress-value{
  background-color:#219653;
}
.password-strength-label-result-Weak{
color:  #B53629
}
.password-strength-label-result-Fair {
    color: #F2994A;
}
/* .password-strength-label-result-Good{
    color: #247BA0;
} */
.password-strength-label-result-Strong {
    color: #219653;
}