/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
    #content-wrapper .searchbargray {
        top: -90px;
        width: 89.5%;
        margin: 10px;
    }

    #content-wrapper .primarybtn.create {
        font-size: 14px;
        padding: 8px 0;
        min-width: 232px;
    }

    #content-wrapper select.selectdropdown-shadow.width {
        position: absolute;
        top: 43px;
        height: 35px;
        padding: 5px;
        right: -53px;
        width: 232px;
        border-radius: 4px;
    }

    #content-wrapper .deals__card .dealsImg{
        border-top-right-radius: 5px;
        border-bottom-left-radius: 0;
    }

    #content-wrapper .float-right .idtag{
        display: block;
        text-align: center;
        margin-right: 0!important;
    }

    #content-wrapper .card-links {
        display: flex;
        justify-content: center;
    }

    #content-wrapper .primarytags {
        justify-content: center;
    }

    #content-wrapper .deal__description p.amount-title, 
    #content-wrapper .deal__description .deals__primarytxt{
        justify-content: center;
        text-align: center;
    }

    #approval .update-img-btn {
        width: 130px!important;
        padding: 5px 0!important;
    }
    
    #approval .update-img-btn.mr-4{
        margin-right: 0!important;
    }
}