.merge-title{
    font-size: 30px;
    font-family: "Roboto", sans-serif;
      font-weight: bold;
      color: #44566C;
  }
  #mergeAccount .close{
    background: #cdcdcd;
      margin: 0;
      border-radius: 100%;
      width: 30px;
      height: 30px;
      color: #44566C;
  }
  #mergeAccount .modal-header .close{padding: 0;}
  #mergeAccount .searchElm{height: 40px;
    background: #f8fafb;
    border: none;
    line-height: 18px;
    font-family: "Roboto", sans-serif;
    font-size: 15px;}
    #mergeAccount  .modal-body{padding: 0;}
  #mergeAccount .close span{position: relative;bottom: 3px;}
  #mergeAccount .samewidth{width: 215px;height: 45px;}
  #mergeAccount .shareddocument th{color: #98A9BC;font-family: "Roboto", sans-serif;font-size: 14px;line-height: 21px;}
  .mergeAccountTable{border-top:1px solid #F2F4F6;}
  #mergeAccount .shareddocument.brokerLender{width: 100% !important;}
  #mergeAccount tbody tr{border-bottom: 1px solid #F2F4F6;}
  .mergeAccountTable .custom-checkbox .custom-control-label::before{border-radius: 0 !important;}
  .mergeAccountTable .custom-checkbox .custom-control-label::after{top: .25rem!important;}